<template>
  <section>
    <form-spinner v-if="loading"/>
    <div v-else-if="canal" class="frame canal">
      <ul class="etapas">
        <li>
          <a href="#" :class="['btn', { 'btn-primary-light': etapa > 1, 'btn-primary': etapa == 1, 'btn-secondary': etapa < 1 }]" @click.prevent="toggleStep(1)">
            1 Detalhes
          </a>
        </li>
        <li>
          <a href="#" :class="['btn', { 'btn-primary-light': etapa > 2, 'btn-primary': etapa == 2, 'btn-secondary': etapa < 2  }]" :disabled="etapa < 2" @click.prevent="toggleStep(2)">
            2 Turmas
          </a>
        </li>
        <li>
          <a href="#" :class="['btn', { 'btn-primary-light': etapa > 3, 'btn-primary': etapa == 3, 'btn-secondary': etapa < 3  }]" :disabled="etapa < 3" @click.prevent="toggleStep(3)">
            3 Funções
          </a>
        </li>
      </ul>
      <template v-if="etapa == 1">
        <header>
          <div>
            <div class="thumb">
              <div :class="['thumbSelector', { active: thumbSelectorActive }]">
                <span :style="{ backgroundImage: `url('${thumbUrl}${canal.iconCanal}.svg')` }" @click.prevent="toggleThumbSelector">
                  <span>
                    <fa :icon="['fas', 'chevron-down']" />
                  </span>
                </span>
                <ul>
                  <li v-for="thumb in thumbs" :key="thumb" :title="thumb.label" @click="selectThumb(thumb)">
                    <span :style="{ backgroundImage: `url('${thumbUrl}${thumb.slug}.svg')` }"></span>
                  </li>
                </ul>
                <span class="backdrop" @click.prevent="toggleThumbSelector"></span>
              </div>
            </div>
            <span class="titleWrap">
              <!-- <fa :icon="['far', 'pencil']" class="icon"/> -->
              <h1 contentEditable="true" ref="nomeCanal" id="nomeCanal" @click="selectContent('nomeCanal')" @input="canal.nomeCanal = $event.target.innerText">
                {{ canal.nomeCanal }}
              </h1>
            </span>
            
          </div>
          <div class="form-group nop nom">
            <textarea-control label="Descrição" v-model="canal.descricaoCanal" required></textarea-control>
          </div>
        </header>
        <div class="typeWrap">
          <h4>Canal de Atendimento</h4>
          <span>
            <div>
              <switch-control @change="changeType()" v-model:checked="tipoCanal"/>
            </div>
            <p>
              Com essa opção selecionada o canal criado será de ticketagem, onde o responsável poderá abrir um chamado. Caso contrário o canal será criado no formato de conversas.
            </p>
          </span>
        </div>
        <div class="subSelection">
          <div>
            <h4>Grupo Familiar</h4>
            <label :class="['check-control','super', { disabled: tipoCanal }]">
              <input type="checkbox" class="check" v-model="familia" @change="changeType()"/>
              <span>Com essa opção selecionada, é possível enviar mensagens para o grupo família, em que todos os responsáveis e aluno podem interagir com a escola na mesma conversa. Este canal não poderá ser editado após a criação.</span>
            </label>
          </div>
          <div v-if="!id">
            <h4>Replicar para todas as unidades</h4>
            <label class="check-control super">
              <input type="checkbox" class="check" v-model="canal.porUnidade"/>
              <span>Com essa opção selecionada, você replicará a criação deste grupo para todas as unidades, sendo necessário criar o grupo apenas uma vez. A edição destes canais deverá ser feita um a um.</span>
            </label>
          </div>
        </div>
      </template>
      <template v-if="etapa == 2">
        <header>
          <h3>Selecione as turmas</h3>
        </header>
        <div class="list-track">
          <div class="lists">
            <div>
              <header>
                <p>{{ this.totalTurmas }} itens</p>
                <a href="#" class="btn btn-secondary btn-small" @click.prevent="selectAll()">Selecionar Todos</a>
              </header>
              <card :listed="false" :boxed="false">
                <div class="search">
                  <input-control placeholder="O que você procura?" cssClass="invis" v-model="segQuery" @keyup="searchTurmas('segmentos')" search preventSearchButton/>
                </div>
                <div class="list-selector" v-if="segmentos">
                  <ul v-for="(segmento, i) in segmentos" :key="segmento">
                    <template v-for="(item, key) in segmento" :key="item">                      
                      <li class="titleItem noselect" @click="selectTurma('title', key, item.turmas, i)">
                        <b>{{ key }}</b>
                      </li>
                      <li v-for="(turma, ii) in item.turmas" :key="turma" @click="selectTurma('item', key, turma, i, ii)" class="noselect">
                        {{ turma.descricao }}
                      </li>
                    </template>
                  </ul>
                </div>
              </card>
            </div>
            <span>
              <fa :icon="['far', 'arrow-right-arrow-left']" />
            </span>
            <div>
              <header>
                <p>{{ totalSelected }} itens selecionados</p>
                <a href="#" class="btn btn-secondary btn-small" @click.prevent="unselectAll()">Remover Todos</a>
              </header>
              <card :listed="false" :boxed="false">
                <div class="search">
                  <input-control  placeholder="O que você procura?" cssClass="invis" v-model="selQuery" @keyup="searchTurmas('selected')" :disabled="totalSelected.length == 0" search preventSearchButton/>
                </div>
                <div class="list-selector" v-if="segmentos && Object.values(selectedTurmas).length > 0">
                  <ul v-for="(segmento, title, i) in selectedTurmas" :key="segmento">
                    <li class="titleItem noselect" @click="removeTurma('title', title, segmento.turmas, i)">
                      <b>{{ title }}</b>
                    </li>
                    <li v-for="(turma, ii) in segmento.turmas" :key="turma" @click="removeTurma('item', title, turma, i, ii)" class="noselect">
                      {{ turma.descricao }}
                    </li>
                  </ul>
                </div>
                <div v-else class="emptyList">
                  <div>
                    <fa :icon="['fas', 'square-dashed-circle-plus']" class="ic" />
                    <h5>Selecione os itens na lista ao lado</h5>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>
      </template>
      <template v-if="etapa == 3">
        <header>
          <h3>Selecione as funções</h3>
        </header>
        <div class="list-track">
          <div class="lists">
            <div>
              <header>
                <p>{{ funcoes.length }} itens</p>
                <a href="#" class="btn btn-secondary btn-small" @click.prevent="selectAllFuncoes()">Selecionar Todos</a>
              </header>
              <card :listed="false" :boxed="false">
                <div class="search">
                  <input-control placeholder="O que você procura?" cssClass="invis" v-model="funcQuery" @keyup="searchFuncoes('funcoes')" search preventSearchButton/>
                </div>
                <div class="list-selector" v-if="funcoes">
                  <ul >
                    <li v-for="(funcao, i) in funcoes" :key="funcao" @click="selectFuncao(funcao, i)" class="noselect">
                        {{ funcao.nome }}
                      </li>
                  </ul>
                </div>
              </card>
            </div>
            <span>
              <fa :icon="['far', 'arrow-right-arrow-left']" />
            </span>
            <div>
              <header>
                <p>{{ funcoesSelecionadas.length }} itens selecionados</p>
                <a href="#" class="btn btn-secondary btn-small" @click.prevent="unselectAllFuncoes()">Remover Todos</a>
              </header>
              <card :listed="false" :boxed="false">
                <div class="search">
                  <input-control placeholder="O que você procura?" cssClass="invis" v-model="sfuncQuery" @keyup="searchFuncoes('selecionadas')" :disabled="funcoesSelecionadas.length == 0" search preventSearchButton/>
                </div>
                <div class="list-selector" v-if="funcoes && funcoesSelecionadas.length > 0">
                  <ul >
                    <li v-for="(funcao, i) in funcoesSelecionadas" :key="funcao" @click="removeFuncao(funcao, i)" class="noselect">
                      {{ funcao.nome }}
                    </li>
                  </ul>
                </div>
                <div v-else class="emptyList">
                  <div>
                    <fa :icon="['fas', 'square-dashed-circle-plus']" class="ic" />
                    <h5>Selecione os itens na lista ao lado</h5>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>
      </template>
    </div>
    <footer>
        <div>
          <a href="#" @click.prevent="goTo('prev')" class="btn btn-secondary" v-if="etapa > 1">Voltar</a>
        </div>
        <div>
          <a href="#" @click.prevent="goTo('next')" class="btn btn-primary" v-if="etapa < 3">Próximo</a>
          <a href="#" @click.prevent="save()" class="btn btn-primary" v-if="etapa == 3">Salvar</a>
        </div>
    </footer>
  </section>
</template>

<script>
import AgendaEduService from '@/services/agendaedu.service.js'
import AdmService from '@/services/adm.service.js'
import FormSpinner from '@/components/utilities/FormSpinner.vue'

export default {
  components: {
    FormSpinner
  },
  props: {
    id: {
      type: [String, Number]
    },
    action: {
      type: String
    }
  },
  computed: {
    totalTurmas () {
      return this.segmentos.reduce((total, school) => {
        for (const [name, data] of Object.entries(school)) {
          console.log(name)
          total += data.turmas.length
        }
        return total
      }, 0)
    },
    totalSelected () {
      return Object.entries(this.selectedTurmas).reduce((total, [name, data]) => {
        console.log(name)
        total += data.turmas.length
        return total
      }, 0)
    },
  },
  data () {
    return {
      loading: false,
      canal: {
        nomeCanal: "Nome do Canal",
        descricaoCanal: "",
        iconCanal: "",
        tipoCanal: "ticket",
        porUnidade: 0,
        turmas: [],
        funcoes: []
      },
      tipoCanal: true,
      familia: false,
      etapa: 1,
      thumbUrl: 'https://static.agendaedu.com/icon_messages/',
      thumbs: [
        {
          label: 'Autorização',
          slug: 'authorization'
        },
        {
          label: 'Café',
          slug: 'coffee'
        },
        {
          label: 'Coordenador',
          slug: 'coordinators'
        },
        {
          label: 'Financeiro',
          slug: 'financer'
        },
        {
          label: 'Geral',
          slug: 'general'
        },
        {
          label: 'Suporte',
          slug: 'helpcenter'
        },
        {
          label: 'Biblioteca',
          slug: 'library'
        },
        {
          label: 'Achados e Perdidos',
          slug: 'lostandfound'
        },
        {
          label: 'Cuidados',
          slug: 'nursing'
        },
        {
          label: 'Nutrição',
          slug: 'nutrition'
        },
        {
          label: 'Ouvidoria',
          slug: 'ombudsman'
        },
        {
          label: 'Direção',
          slug: 'principal'
        },
        {
          label: 'Psicologia',
          slug: 'psychology'
        },
        {
          label: 'Agendamento',
          slug: 'scheduling'
        },
        {
          label: 'Secretaria',
          slug: 'secretary'
        },
        {
          label: 'Professor',
          slug: 'teacher'
        },
        {
          label: 'Tesouraria',
          slug: 'treasury'
        },
        {
          label: 'Armazém',
          slug: 'warehouse'
        }
      ],
      thumbSelectorActive: false,
      segmentos: null,
      selectedTurmas: {},
      segmentosBkp: null,
      segmentosLiveBkp: null,
      selectedLiveBkp: {},
      funcoesBkp: null,
      funcoesSelecionadasBkp: null,
      segQuery: null,
      selQuery: null,
      funcQuery: null,
      sfuncQuery: null,
      searchWait: null,
      wait: {
        canal: false,
        turmas: false,
        funcoes: false
      },
      funcoes: [],
      funcoesSelecionadas: []
    }
  },
  mounted () {
    this.loading = true

    if ( this.id ) this.getCanal()
    else this.wait.canal = true

    this.getTurmas()
    this.getFuncoes()
  },
  methods: {
    getCanal () {
      AgendaEduService.getCanal(this.id).then(
        response => {
          console.log('getCanal:', response.data)
          
          this.canal = response.data
          this.tipoCanal = this.canal.tipoCanal == 'ticket'
          this.familia =  this.canal.tipoCanal == 'family'

          this.wait.canal = true
          this.finishLoad()
        },
        () => {
          console.error("Canal não disponível")
          // this.loading = false
        }
      )
    },
    getTurmas () {
      AgendaEduService.getTurmas().then(
        response => {
          console.log("getTurmas:", response.data)

          this.segmentos = response.data
          this.segmentosBkp = Object.assign([], response.data)
          this.segmentosLiveBkp = Object.assign([], response.data)

          this.wait.turmas = true
          this.finishLoad()

        },
        error => {
          console.error(error)
        }
      )
    },
    getFuncoes () {
      AdmService.getFuncoes().then(
        response => {
          this.funcoes = response.data
          this.funcoesBkp = Object.assign([], response.data)
          this.wait.funcoes = true
          this.finishLoad()

        },
        error => {
          console.error(error)
        }
      )
    },
    addTurmasToList () {
      this.segmentos.map((s,i) => {
        Object.keys(s).map( k => {
          s[k].turmas.map(( m, ii ) => {
            if ( this.canal.turmas.includes(m.idTurma) ) {
              this.selectTurma('item', k, m, i, ii)
            }
          })
        })
      })
    },
    addFuncoesToList () {
      this.funcoes.map(( k, i) => {
        if ( this.canal.funcoes.includes(k.idFuncao) ) {
          this.selectFuncao(k, i)
        }
      })
    },
    finishLoad () {
      const done = !Object.values(this.wait).some( a => !a)
      // console.log("done:", done)
      if ( done ) {
        this.loading = false

        if ( this.id ) {
          this.addFuncoesToList()
          this.addTurmasToList()
        }
      }
    },
    selectThumb ( thumb ) {
      this.canal.iconCanal = thumb.slug
      this.toggleThumbSelector()
    },
    selectContent( ref ) {
      const el = this.$refs[ref]
      // const contentDiv = document.getElementById(ref)
      
      if (el) {
        var sel, range
        // var el = document.getElementById(id); //get element id
        if (window.getSelection && document.createRange) { //Browser compatibility
          sel = window.getSelection()

          if(sel.toString() == ''){ //no text selection
            setTimeout(function(){
              range = document.createRange(); //range object
              range.selectNodeContents(el); //sets Range
              sel.removeAllRanges(); //remove all ranges from selection
              sel.addRange(range);//add Range to a Selection.
            }, 1)
          }
        }
        
      }
    },
    toggleThumbSelector () {
      this.thumbSelectorActive = !this.thumbSelectorActive
    },
    changeType () {
      if ( this.changeType ) {
        this.canal.tipoCanal = 'ticket'
        this.familia = false
      } else {
        this.canal.tipoCanal = this.familia ? 'family' : 'private'
        this.tipoCanal = false
      }
    },
    goTo ( dir ) {
      if ( dir == 'prev' ) {
        if ( this.etapa > 1 ) {
          this.toggleStep( this.etapa - 1 )
        }
      }
      if ( dir == 'next' ) {
        if ( this.etapa < 3 ) {
          const valid = this.saveEtapa(this.etapa)
          if ( valid ) this.toggleStep( this.etapa + 1 )
          
        }
      }
    },
    toggleStep ( step ) {
      this.etapa = step
    },
    selectTurma ( level, key, item, i, ii ) {
      if (!this.selectedTurmas[key]) {
        this.selectedTurmas[key] = { turmas: [] }
      }

      if ( level == 'title' ) {
        this.selectedTurmas[key].turmas.push(...item)
        this.segmentos = this.segmentos.filter(a => !a[key])
      }

      if ( level == 'item' ) {
        this.selectedTurmas[key].turmas.push(item)
        this.segmentos[i][key].turmas.splice(ii,1)
      }

      this.segmentosLiveBkp = Object.assign([], this.segmentos)
      this.selectedLiveBkp = Object.assign({}, this.selectedTurmas)
    },
    removeTurma ( level, key, item, i, ii) {
      let segmento = this.segmentos.find(a => a[key])
      
      if (!segmento) {
        segmento = { [key]: { turmas: [] } }
        this.segmentos.push(segmento)
      }

      if ( level == 'title' ) {
        const turmas = this.selectedTurmas[key].turmas
        console.log("turmas:", turmas)

        segmento[key].turmas.push(...turmas)

        delete this.selectedTurmas[key]
      }
      if ( level == 'item' ) {
        segmento[key].turmas.push(item)

        this.selectedTurmas[key].turmas.splice(ii, 1)

        if (this.selectedTurmas[key].turmas.length === 0) {
          delete this.selectedTurmas[key]
        }
      }

      this.segmentosLiveBkp = Object.assign([], this.segmentos)
      this.selectedLiveBkp = Object.assign({}, this.selectedTurmas)
    },
    selectFuncao ( funcao, i ) {
      this.funcoesSelecionadas.push(funcao)
      this.funcoesSelecionadasBkp = Object.assign([], this.funcoesSelecionadas)
      // console.log("funcoesSelecionadasBkp:", this.funcoesSelecionadasBkp)
      this.funcoes.splice(i,1)
    },
    removeFuncao ( funcao, i ) {
      this.funcoes.push(funcao)
      this.funcoesSelecionadas.splice(i,1)
    },
    searchTurmas ( src ) {
      if ( this.searchWait ) clearTimeout(this.searchWait)
      
      this.searchWait = setTimeout(() => {
        if ( src == 'segmentos' ) {
          
          const query = this.segQuery.toLowerCase()

          if ( query.length > 0 ) {
            const result = this.segmentos.map(segmento => {
            const filteredSchool = {}

            for (const [schoolName, schoolData] of Object.entries(segmento)) {
              const schoolNameMatch = schoolName.toLowerCase().includes(query);

              const filteredTurmas = schoolData.turmas.filter(turma =>
                turma.descricao.toLowerCase().includes(query)
              )

              if (schoolNameMatch) {
                filteredSchool[schoolName] = { turmas: schoolData.turmas }
              }

              else if (filteredTurmas.length > 0) {
                filteredSchool[schoolName] = { turmas: filteredTurmas }
              }
            }

            return Object.keys(filteredSchool).length > 0 ? filteredSchool : null
            })
            .filter(Boolean)

            this.segmentos = result.length > 0 ? result : Object.assign([], this.segmentosBkp)
            this.segmentosLiveBkp = Object.assign([], this.segmentos)
          } else {
            this.segmentos = Object.assign([], this.segmentosBkp)
          }
        }

        if ( src == 'selected' ) {
          const query = this.selQuery.toLowerCase()

          if ( query.length > 0 ) {
            const filteredResult = {}

            for (const [schoolName, schoolData] of Object.entries(this.selectedTurmas)) {
              if (schoolName.toLowerCase().includes(query)) {
                filteredResult[schoolName] = schoolData
              } else {
                const filteredTurmas = schoolData.turmas.filter(turma =>
                  turma.descricao.toLowerCase().includes(query)
                );
                if (filteredTurmas.length > 0) {
                  filteredResult[schoolName] = { ...schoolData, turmas: filteredTurmas }
                }
              }
            }

            // Update filtered result schools
            this.selectedTurmas = Object.keys(filteredResult).length > 0 ? filteredResult : this.selectedTurmas

            // this.selectedTurmas = result.length > 0 ? result : Object.assign([], this.selectedLiveBkp)
          } else {
            this.selectedTurmas = Object.assign({}, this.selectedLiveBkp)
          }
        }
        

      },200)
    },
    searchFuncoes ( src ) {
      if ( src == 'funcoes' ) {
        if ( this.searchWait ) clearTimeout(this.searchWait)
        
        const query = this.funcQuery.toLowerCase()

        this.searchWait = setTimeout(() => {
          if ( query.length > 0 ) {
            const result = this.funcoes.filter( a => {
              return a.nome.toLowerCase().includes(query)
            })
            this.funcoes = result.length > 0 ? result : this.funcoesBkp
          } else {
            this.funcoes = Object.assign([], this.funcoesBkp)
          }
        },200)
      }
      if ( src == 'selecionadas' ) {
        if ( this.searchWait ) clearTimeout(this.searchWait)
        const query = this.sfuncQuery.toLowerCase()

        this.searchWait = setTimeout(() => {
          if ( query.length > 0 && this.funcoesSelecionadas.length > 0 ) {
            const result = this.funcoesSelecionadas.filter( a => {
              return a.nome.toLowerCase().includes(query)
            })
            // console.log("this.funcoesSeleciondasBkp:", this.funcoesSelecionadasBkp)

            this.funcoesSelecionadas = result.length > 0 ? result : this.funcoesSelecionadasBkp
          } else {
            this.funcoesSelecionadas = Object.assign([], this.funcoesSelecionadasBkp)
          }
        },200)
      }
    },
    selectAll () {
      this.selectedTurmas = {}

      this.segmentos.forEach(segmento => {
        for (const [name, data] of Object.entries(segmento)) {
          this.selectedTurmas[name] = data
        }
      })

      this.segmentos = []
    },
    unselectAll () {
      this.segmentos = Object.assign([], this.segmentosBkp)

      this.selectedTurmas = {}
    },
    selectAllFuncoes () {
      this.funcoesSelecionadas = Object.assign([], this.funcoes)
      this.funcoes = []
    },
    unselectAllFuncoes () {
      this.funcoes = Object.assign([], this.funcoesSelecionadas)
      this.funcoesSelecionadas = []
    },
    saveEtapa ( etapa ) {
      if ( etapa == 1 ) {
        if ( !this.canal.nomeCanal || this.canal.nomeCanal == 'Nome do Canal' ) {
          window.toaster.emit('open', {style: "error", message: "Escolha um nome para o canal", floater: true})
        } else {
          if ( !this.canal.descricaoCanal ) {
            window.toaster.emit('open', {style: "error", message: "Escreva uma descrição sobre o canal", floater: true})
          } else {
            if ( !this.canal.iconCanal ) {
              window.toaster.emit('open', {style: "error", message: "Escolha um ícone para o canal", floater: true})
            } else {
              return true
            }
          }
        }
      }

      if ( etapa == 2 ) {
        if ( this.totalSelected > 0 ) {
          // this.canal.turmas 
          console.log('selectedTurmas:', this.selectedTurmas)
          const extractTurmaIds = (data) => 
            Object.values(data)
              .flatMap(unidade => unidade.turmas)
              .map(turma => turma.idTurma)
          
          this.canal.turmas = extractTurmaIds(this.selectedTurmas)
          return true
          
        } else {
          window.toaster.emit('open', {style: "error", message: "Selecione ao menos uma turma para prosseguir", floater: true})
        }
      }

      if ( etapa == 3) {
        if ( this.funcoesSelecionadas.length > 0 ) {
          this.canal.funcoes = this.funcoesSelecionadas.flatMap( a => a.idFuncao)
          return true
        } else {
          window.toaster.emit('open', {style: "error", message: "Selecione ao menos uma função para prosseguir", floater: true})
        }
      }
    },
    save () {
      const valid = this.saveEtapa(3)
      if ( valid ) {
        this.loading = true
        AgendaEduService.saveCanal( this.canal ).then(
          response => {
            if ( response.status == 200 ) {
              window.toaster.emit('open', {style: "success", message: "Tudo certo! Canal salvo com sucesso.", floater: true})
              this.loading = false
              this.$emit("onSave")
            }
          },
          error => {
            this.loading = false
            window.toaster.emit('open', {style: "error", message: "Ops! Algo aconteceu. Não foi possível salvar o canal. " + error.response.data, floater: true})
          }
        )
      }
      console.log('canal:', this.canal)
    }
  }
}
</script>

<style lang="scss" scoped>
  .canal {
    padding: $mg 0 $mg_large 0;

    >header {
      display: block;
      margin-bottom: $hmg_mid;

      >div {
        &:first-child {
          display: flex; align-items: center;
          gap: $mg_mini;
          margin-bottom: $hmg_mid;

          span {
            flex: 1;

            h1 {
              flex: 1;
              padding: $mg_mini;
              @extend %transition_3o;
              line-height: 1;

              &:focus, &:hover {
                background-color: $color-secondary;
                border-radius: $border-radius;
                outline: none; padding: $mg_mini;
                border: $border-component;

                .icon {
                  opacity: 1 !important;
                  left: 0;
                }
              }
            }
            
            .icon {
              position: absolute;
              top: 45%; transform: translateY(-50%);
              left: $mg_mini;
              opacity: 0; left: $hmg_mini;
              font-size: 16px;
              @extend %transition_3o;
              pointer-events:none;
              margin-bottom: $hmg_mini
            }
          }
        }
      }

      .thumbSelector {
        position: relative; 

        >span {
          &:not(.backdrop) {
            display: flex; flex-direction: column;
            align-items: center; justify-content: center;
            width: 50px; height: 50px;               
            flex-shrink: 0;
            border-radius: 100%; border: $border-component;
            background-size: cover; background-repeat: no-repeat;
            background-position: center; background-color: $color-secondary;
            
            > span {
              display: flex; flex-direction: column;
              align-items: center; justify-content: center;
              @extend %transition_3o;
              font-size: 20px; color: $color-primary;
              width: 40px; height: 40px; min-width: 40px; min-height: 40px;
              max-width: 40px; max-height: 40px;

              background-color: rgba(255,255,255,.1);
              backdrop-filter: blur(4px);
              border-radius: 100%;
              opacity: 0;
            }

            &:hover {
              >span {
                opacity: 1;
              }
            }
          }
          &.backdrop {
            pointer-events:none;
          }
        }
        
        ul {
          width: 162px; height: auto;
          position: absolute; z-index: 600;
          display: flex; align-items: flex-start;
          flex-wrap: wrap; gap: $mg_mini;
          padding: $hmg-mid; margin: 0;
          bottom: -$mg_mini;
          transform: translateY(80%);
          background-color: $color-secondary;
          @extend %transition_4o;
          pointer-events: none;
          opacity: 0;
          border-radius: $border-radius;
          box-shadow: 0 6px 20px rgba(0,0,0,0.1);

          li {
            padding: 0;
            margin: 0;
            list-style: none;

            > span {
              display: block;
              width: 34px; height: 34px;
              border-radius: 100%;
              background-size: cover; background-repeat: no-repeat;
              background-position: center;
              background-color: $color-bg;
              border: 1px dashed $color-secondary;
              @extend %transition_4o;
            }

            &:hover {
              >span {
                border: 3px solid $color-primary
              }
            }
          }

         
        }

        &.active {
          ul {
            opacity: 1;
            transform: translateY(100%);
            pointer-events: all;
          }
          .backdrop {
            position: fixed; width: 100vh; height: 100vh;
            left: 0; top: 0;
            display: block;
            z-index: 590;
            pointer-events: all
          }
        }
      }
    }

    >div {
      margin-top: $mg;
    }
  }

  .etapas {
    display: flex;
    align-items: center; align-content: center;
    justify-content: center;
    gap: $hmg_mid;
    margin: 0 0 $mg 0; padding: 0;

    li {
      list-style: none;
      padding: 0;
    }
  }

  .list-track { 
    .lists {
      display: flex;
      align-items: center;
      gap: $mg_mini;

      >div {
        flex: 1;
      }
      >span {
        font-size: 16px;
        margin-top: $hmg;
      }
      header {
        display: flex; padding: 0;
        align-items: center; align-content: center;
        justify-content: space-between;
        margin-bottom: $hmg_mid;

        p {
          margin: 0; padding: 0;
        }
      }

      .search {
        border-bottom: $border-component;
      }

      .list-selector {
        height: 35vh;
        overflow-y: auto;
        @extend %scrollbar_light;
      }

      .emptyList {
        position: relative;
        height: 35vh;
        display: flex;
        align-items: center;
        justify-content: center;

        > div {
          position: absolute;
          max-width: 200px;
          margin: auto;
          text-align: center;

          .ic {
            font-size: 64px;
          }
          h5 {
            margin-top: $mg_mini
          }
        }
      }

      ul {
        padding: 0; margin: 0;
        
        li {
          list-style: none;
          border-bottom: $border-component;
          padding: $hmg_small $mg_mid;
          cursor: pointer;
          @extend %transition_3o;
          
          &.titleItem {
            border-bottom: $border-component-double;
            padding: $hmg_small;
          }

          &:last-child {
            border: 0;
          }

          &:hover {
            background-color: $color-bg
          }
        }
      }
    }
  }

  footer {
    position: fixed; bottom: 0;
    display: flex; align-items: center;
    justify-content: space-between;
    width: calc(100vw - 60px);
    padding: $hmg_mid;
    border-top: $border-component;
  }

  .titleWrap {
    position: relative
  }

  .subSelection { 
    display: flex; align-items: flex-start;
    align-content: center;
    gap: $mg;
    
    >div {
      flex: 1;
      max-width: 50%;
    }
  }

  .typeWrap {
    margin-bottom: $hmg_mid;
    h4 { margin-bottom: $mg_mini}

    >span {
      display: flex; align-items: center;
      align-content: center; gap: $hmg_mid;
      padding: $hmg_mid;
      background-color: $color-secondary;
      border-radius: $border-radius;

      p {
        margin: 0; padding: 0;
      }
    }

  }
</style>




