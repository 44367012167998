import axios from 'axios'
import authHeader from './auth-header'
import { formatDate } from '@/services/utils.service.js'

class MatriculaService {
  
  getPessoaByIdPessoaOuCpf ( idPessoa, cpf ) {
    return axios.get(process.env.VUE_APP_API_URL + 'pessoa/'+ idPessoa +'/' + cpf, { headers: authHeader() })
  }

  getMatriculas ( data ) {
    const idUnidade = data.idUnidade ?? 0
    const idSerie = data.idSerie ?? 0
    const idCurso = data.idCurso ?? 0
    const idTipoCurso = data.idTipoCurso ?? 0
    const idTurma = data.idTurma ?? 0
    const idTipoSituacao = data.idTipoSituacao ?? 0
    const idPeriodoLetivo = data.idPeriodoLetivo ?? 0
    const idAluno = data.idAluno ?? 0
    const validado = data.validado ?? 0
    const tipoNome = data.tipoNome ?? 0
    const nome = data.nome ?? 0

    return axios.get(process.env.VUE_APP_API_URL + `matricula/matriculas/${idUnidade}/${idSerie}/${idCurso}/${idTipoCurso}/${idTurma}/${idTipoSituacao}/${idPeriodoLetivo}/${idAluno}/${validado}/${tipoNome}/${nome}`, { headers: authHeader() })
  }

  getPessoaByNome( nome ) {
    return axios.get(process.env.VUE_APP_API_URL + 'pessoa/0/0/' + nome, { headers: authHeader() })
  }

  getAlunoByIdMatricula ( idMatricula ) {
    return axios.get(process.env.VUE_APP_API_URL + 'matricula/aluno/' + idMatricula, { headers: authHeader() })
  }

  createAluno ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'matricula/aluno', data, { headers: authHeader() })
  }

  updateAluno ( data, idMatricula ) {
    return axios.put(process.env.VUE_APP_API_URL + 'matricula/aluno/' + idMatricula, data, { headers: authHeader() })
  }

  saveAluno ( data, idAluno ) {
    if ( idAluno ) {
      return this.updateAluno(data, idAluno)
    } else {
      return this.createAluno(data)
    }
  }

  getFiliacao ( idMatricula ) {
    return axios.get(process.env.VUE_APP_API_URL + 'matricula/filiacao/' + idMatricula, { headers: authHeader() })
  }

  saveFiliacao ( idMatricula, data ) {
    return axios.put(process.env.VUE_APP_API_URL + 'matricula/filiacao/' + idMatricula, data, { headers: authHeader() })
  }

  getRespFin ( idMatricula ) {
    return axios.get(process.env.VUE_APP_API_URL + 'matricula/respfin/' + idMatricula, { headers: authHeader() })
  }

  saveRespFin ( idMatricula, data ) {
    if ( data.Basicos.idPessoa ) {
      return axios.put(process.env.VUE_APP_API_URL + 'matricula/respfin/' + idMatricula, data, { headers: authHeader() })
    } else {
      return axios.post(process.env.VUE_APP_API_URL + 'matricula/respfin', data, { headers: authHeader() })
    }
  }

  getRespPed ( idMatricula ) {
    return axios.get(process.env.VUE_APP_API_URL + 'matricula/respped/' + idMatricula, { headers: authHeader() })
  }
  
  saveRespPed ( idMatricula, data ) {
    if ( data.Basicos.idPessoa ) {
      return axios.put(process.env.VUE_APP_API_URL + 'matricula/respped/' + idMatricula, data, { headers: authHeader() })
    } else {
      return axios.post(process.env.VUE_APP_API_URL + 'matricula/respped', data, { headers: authHeader() })
    }
  }

  getPlanosPagamento ( idMatricula ) {
    return axios.get(process.env.VUE_APP_API_URL + 'matricula/planosPagamento/' + idMatricula, { headers: authHeader() })
  }

  savePlanosPagamento ( idMatricula, data ) {
    return axios.put(process.env.VUE_APP_API_URL + 'matricula/plano/' + idMatricula, data, { headers: authHeader() })
  }

  getFichaSaude ( idMatricula ) {
    return axios.get(process.env.VUE_APP_API_URL + 'matricula/fichasaude/' + idMatricula, { headers: authHeader() })
  }

  saveFichaSaude ( idMatricula, data ) {
    if ( data.fichaSaude.idps_saude ) {
      return axios.put(process.env.VUE_APP_API_URL + 'matricula/fichasaude/' + idMatricula, data, { headers: authHeader() })
    } else {
      return axios.post(process.env.VUE_APP_API_URL + 'matricula/fichasaude', data, { headers: authHeader() })
    }
  }

  getFichaSaida ( idMatricula ) {
    return axios.get(process.env.VUE_APP_API_URL + 'matricula/saida/' + idMatricula, { headers: authHeader() })
  }

  saveFichaSaida ( idMatricula, data ) {
    return axios.put(process.env.VUE_APP_API_URL + 'matricula/saida/' + idMatricula, data, { headers: authHeader() })
  }

  getContrato ( idMatricula, isGestao ) {
    const gestao = isGestao ? '/portalGestao' : ''
    return axios.get(process.env.VUE_APP_API_URL + 'matricula/contrato/' + idMatricula + gestao, { headers: authHeader(), responseType: "blob" })
  }
  
  saveContrato ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'matricula/contrato', data, { headers: authHeader() })
  }

  getTipoArrecadacao () {
    return axios.get(process.env.VUE_APP_API_URL + 'tiposarrecadacao/2', { headers: authHeader() })
  }

  getTipoDescontos ( idMatricula ) {
    return axios.get(process.env.VUE_APP_API_URL + 'tiposdescontomatricula/' + idMatricula, { headers: authHeader() })
  }
  
  getDescontos () {
    return axios.get(process.env.VUE_APP_API_URL + 'descontos', { headers: authHeader() })
  }

  getPeriodoLetivoByMatricula ( idMatricula ) {
    return axios.get(process.env.VUE_APP_API_URL + 'periodoletivobymatricula/' + idMatricula, { headers: authHeader() })
  }

  saveDescontoMatricula ( data ) {
    const action = data.acao.split('-')[1]
    if ( action == 'update' ) {
      return axios.put(process.env.VUE_APP_API_URL + 'tipodescontomatricula/' + data.idTipoDescontoMatricula, data, { headers: authHeader() })
    } else {
      return axios.post(process.env.VUE_APP_API_URL + 'tipodescontomatricula', data, { headers: authHeader() })
    }
  }

  deleteDescontoMatricula ( descontoMatricula ) {
    return axios.delete(process.env.VUE_APP_API_URL + 'tipodescontomatricula/' + descontoMatricula, { headers: authHeader() })
  }

  getHomeAppRema ( tipoCurso ) {
    return axios.get(process.env.VUE_APP_API_URL + 'matricula/home/' + tipoCurso, { headers: authHeader() })
  }

  getUnidades () {
    return axios.get(process.env.VUE_APP_API_URL + 'unidades', { headers: authHeader() })
  }

  getContratoEntregue ( idMatricula ) {
    return axios.get(process.env.VUE_APP_API_URL + 'matricula/documento/clicksign/entregue/' + idMatricula, { headers: authHeader() })
  }

  getContratoClickSign  ( idClickSign ) {
    return axios.get(process.env.VUE_APP_API_URL + 'matricula/documento/clicksign/' + idClickSign, { headers: authHeader() })
  }

  reenviaContratoClickSign ( idMatricula ) {
    return axios.get(process.env.VUE_APP_API_URL + 'matricula/contratoRegerar/' + idMatricula, { headers: authHeader() })
  }

  integraMatricula ( idMatricula ) {
    return axios.put(process.env.VUE_APP_API_URL + 'matricula/integracao/matricula/' + idMatricula, null, { headers: authHeader() })
  }

  integraFiliacao ( idMatricula  ) {
    return axios.put(process.env.VUE_APP_API_URL + 'matricula/integracao/filiacao/' + idMatricula, null, { headers: authHeader() })
  }

  integraResponsavel ( idMatricula, tipo ) {
    return axios.put(process.env.VUE_APP_API_URL + 'matricula/integracao/responsavel/' + idMatricula + '/' + tipo, null, { headers: authHeader() })
  }

  integraMensalidade ( idMatricula ) {
    return axios.put(process.env.VUE_APP_API_URL + 'matricula/integracao/mensalidade/' + idMatricula, null, { headers: authHeader() })
  }

  integraDesconto ( idMatricula ) {
    return axios.put(process.env.VUE_APP_API_URL + 'matricula/integracao/mensalidade/recalculo/novoapollo/' + idMatricula, null, { headers: authHeader() })
  }

  matricularItinerarios ( idMatricula, itinerarios ) {
    return axios.post(process.env.VUE_APP_API_URL + 'matricula/inscricaoItinerarios/' + idMatricula, itinerarios, { headers: authHeader() })
  }

  desmatricularItinerarios ( idMatricula, itinerarios ) {
    return axios.post(process.env.VUE_APP_API_URL + 'matricula/itinerarios/remover/' + idMatricula, itinerarios, { headers: authHeader() })
  }

  previewPropostasRema ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'proximociclo/propostasrema/preview', data, { headers: authHeader() })
  }

  previewRemovePropostasRema ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'proximociclo/propostasrema/preview/remover', data, { headers: authHeader() })
  }

  createPropostasRema ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'proximociclo/gerar/propostasrema', data, { headers: authHeader() })
  }

  removePropostasRema ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'proximociclo/remover/propostasrema/full', data, { headers: authHeader() })
  }

  validateMatInfo ( idMatricula ) {
    return axios.get(process.env.VUE_APP_API_URL + 'proximociclo/get/rematricula/bloqueios/erros/cadastro/' + idMatricula, { headers: authHeader() })
  }

  getBloqueios ( idAluno ) {
    return axios.get(process.env.VUE_APP_API_URL + 'matricula/getallbloqueiosbyaluno/' + idAluno, { headers: authHeader() })
  }

  updateBloqueios ( bloqueio ) {
    return axios.post(process.env.VUE_APP_API_URL + 'matricula/aluno/bloqueio/change', bloqueio, { headers: authHeader() })
  }

  trocaTurma ( idMatricula, data ) {
    return axios.put(process.env.VUE_APP_API_URL + 'matricula/trocaturma/' + idMatricula, data, { headers: authHeader() })
  }

  remanejar ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'matricula/remanejamento', data, { headers: authHeader() })
  }

  getAlertasCentral ( idAluno ) {
    return axios.get(process.env.VUE_APP_API_URL + 'centraldoaluno/alertas/' + idAluno, { headers: authHeader() })
  }

  getParcelasPreview ( idMatricula ) {
    return axios.get(process.env.VUE_APP_API_URL + 'matricula/get/contrato/preview/' + idMatricula, { headers: authHeader() })
  }

  getPendencias ( idMatricula ) {
    // matricula/verifica/pendencias/223847
    return axios.get(process.env.VUE_APP_API_URL + 'matricula/verifica/pendencias/' + idMatricula, { headers: authHeader() })
  }

  gerarContrato ( options ) {
    if ( options.portal == 'portalGestao' ) {
      return axios.post(process.env.VUE_APP_API_URL + 'matricula/gerar/contrato', options, { headers: authHeader(), responseType: "blob" })
    } else {
      return axios.post(process.env.VUE_APP_API_URL + 'matricula/gerar/contrato', options, { headers: authHeader()}) 
    }
  }
  
  getContratoStatus ( contrato ) {
    return axios.get(process.env.VUE_APP_API_URL + 'matricula/verificar/contrato/clicksign/' + contrato, { headers: authHeader() })
  }

  getPlanosBySerie ( options ) {
    const idSerie = options.idSerie ?? 0
    const idFuncao = options.idFuncao ?? 0
    const tipoMatricula = options.tipoMatricula ?? 1

    return axios.get(process.env.VUE_APP_API_URL + `get/alcada/${idSerie}/${idFuncao}/${tipoMatricula}`, { headers: authHeader() })
  }

  processPlanos ( planos ) {
    const newPlanos = []
    const arrecadacoes = {
      'ENT': 'entrada',
      'MEN': 'mensalidades',
      'ANU': 'anuidade'
    }

    planos.map( k => {
      console.log("k:", k)
      
      const p = {
        ...k
      }
      const total = this.sumValuesByDate(k.planosPagamento)
      console.log("total:", total)
      
      total.map( t => {
        const target = arrecadacoes[t.tipo]
        p[target] = {
          ...t
        }
      })

      newPlanos.push(p)
    })

    return newPlanos
  }

  getHasMD ( idMatricula )  {
    return axios.get(process.env.VUE_APP_API_URL + 'centraldoaluno/verifica/compra/md/' + idMatricula, { headers: authHeader() })
  }

  gerarCompraMD ( idMatricula ) {
    return axios.post(process.env.VUE_APP_API_URL + 'venda/create/boletao', { idMatricula: idMatricula }, { headers: authHeader() })
  }

  sumValuesByDate(data) {
    return Object.values(data.reduce((acc, object) => {
      // console.log('object:', object)
      const type = object.siglaFront

      acc[type] = acc[type] || { 
        dataFinal: formatDate(object.dataFinal),
        dataInicio: formatDate(object.dataInicio),
        mesInicio: object.mesInicio,
        valorTotal: 0,
        valorTotalDesconto: 0,
        valorParcela: 0,
        valorParcelaDesconto: 0,
        mesFinal: null,
        numeroParcelas: object.numeroParcelas,
        tipo: object.siglaFront
      }
      acc[type].valorTotal += object.valorTotal ? Number(object.valorTotal) : 0
      acc[type].valorTotalDesconto += object.valorTotalDesconto? Number(object.valorTotalDesconto) : 0
      acc[type].valorParcela += object.valorParcela ? Number(object.valorParcela) : 0
      acc[type].valorParcelaDesconto += object.valorParcelaDesconto ? Number(object.valorParcelaDesconto) : 0


      const parseMesInicio = object.mesInicio.split("/")
      const mesInicio = parseFloat(parseMesInicio[0])
      const mesFinal = mesInicio + ( object.numeroParcelas - 1 )
      acc[type].mesFinal = mesFinal + '/' + parseMesInicio[1]

      return acc
    }, {}))
  }
}

export default new MatriculaService()
