<template>
  <controller>
    <div class="page-title">
      <h4 class="text-capitalize">Pedidos</h4>
    </div>
    <div class="page-inner" v-if="!loading">
      <div class="search-filter frame mini fixed">
        <form @keyup.enter="runFilter" @submit.prevent="">
          <div class="row no-padd">
            <div class="col-md-12 form-group">
              <label>Nome</label>
              <input-control v-model="filter.nome" placeholder="Aluno ou Responsável" :autocomplete="true"/>
            </div>
            <div class="col-md-6 form-group">
              <label>Unidade</label>
              <select-control v-model="filter.unidade" :options="unidades" placeholder="Selecione" @change="getPeriodosLetivos()"/>
            </div>
            <div class="col-md-6 form-group">
              <label>Período Letivo</label>
              <select-control v-model="filter.periodoLetivo" :options="periodosLetivos" placeholder="Selecione" @change="getSeries()" :disabled="loadingPeriodos"/>
            </div>
            <div class="col-md-6 form-group">
              <label>Série</label>
              <select-control v-model="filter.serie" :options="series" placeholder="Selecione" @change="getTurmas()" :disabled="loadingSeries"/>
            </div>
            <div class="col-md-6 form-group">
              <label>Turma</label>
              <select-control v-model="filter.turma" :options="turmas" placeholder="Selecione"  :disabled="loadingTurmas"/>
            </div>
            <div class="col-md-6 form-group">
              <label>Depósito</label>
              <select-control v-model="filter.depositos" :options="depositos" placeholder="Selecione" />
            </div>
            <div class="col-md-6 form-group">
              <label>Fornecedores</label>
              <select-control v-model="filter.fornecedores" :options="fornecedores" placeholder="Selecione" />
            </div>
            <div class="col-md-4 form-group">
              <label>Data Pedido</label>
              <input-control v-model="filter.dataPedido" placeholder="Data" type="date" />
            </div>
            <div class="col-md-4 form-group">
              <label>Data Recebimento</label>
              <input-control v-model="filter.dataEntrega" placeholder="Data" type="date" />
            </div>
            <div class="col-md-4 form-group">
              <label>Data Prevista Recebimento</label>
              <input-control v-model="filter.dataPrevisaEntrega" placeholder="Data" type="date" />
            </div>
            <!-- getCalendarios -->
            <div class="col-md-6 form-group">
              <label>Calendários</label>
              <select-control v-model="filter.calendario" :options="calendarios" placeholder="Selecione" />
            </div>
            <div class="col-md-6 form-group checks">
              <label class="check-control">
                <input type="checkbox" class="check" v-model="filter.recebido" />
                <span class="">Apenas Recebidos</span>
              </label>
            </div>
          </div>
          <div class="col-md-12 form-actions form-group">
            <a href="#" class="btn btn-primary" @click.prevent="runFilter()">
              Filtrar
            </a>
            <a href="#" class="btn btn-secondary" @click.prevent="clear()">
              Limpar
            </a>
          </div>
        </form>
      </div>
      <section :class="['collapseList', { lock: processing }]">
        <div class="collapseFilter" v-if="filtered">
          <div>
            <div class="iconWrap">
              <fa :icon="['fas', 'filter-list']"></fa>
            </div>
            <div class="searchWrap">
              <input-control v-model="searchQuery" @keyup="searchList" placeholder="Buscar..." cssClass="" :readonly="processing"></input-control>
            </div>
            <div class="searchWrap">
              <select-control v-model="orderBy" :options="orders" @input="sortList" placeholder="Ordenar por..." cssClass="" :disable="processing"></select-control>
            </div>
            <div class="searchWrap">
              <select-control v-model="filterType" :options="filterTypes" @input="filterList" placeholder="Filtrar por..." cssClass="" :disable="processing"></select-control>
            </div>
          </div>
          <div class="multitask-actions">
            <transition name="slideup-small">
              <label class="check-control">
                <span class="">Todos</span>
                <input type="checkbox" class="check" value="todos" name="todos" v-model="todos" @change="selectAll"/>
              </label>
            </transition>
          </div>
        </div>
        <ul v-if="pedidos.length > 0">
          <!-- <label>
            <fa :icon="['fas', 'user-tie']" class="icon"/>
            {{ item.checkouts[0].nome.toUpperCase() }}
          </label> -->
          <li v-for="(pedido, i) in pedidos" :key="pedido" @click="!preventParentClick ? toggleCollapse(true, i, pedido) : null" :class="{ active: pedido.collapse }">
            <card :boxed="true" overflow="visible">
              <div>
                <header>
                  <div>
                    <span :class="{active: pedido.collapse}" @mouseenter="preventParentClick = true" @mouseleave="preventParentClick = false" @click="toggleCollapse(false, i, pedido)"></span>
                  </div>
                  <!-- <span class="fixed-cell small-cell">
                    {{ pedido.idpedidomaterial }}
                  </span> -->
                  <span class="">
                    {{ pedido.codigo }}
                  </span>
                  <span class="large-cell">
                    {{ pedido.aluno }}
                  </span>
                  <span class="fixed-cell">
                    {{ formatMoney(pedido.valorPedido) }}
                  </span>
                  <span class="small-cell">
                    {{ pedido.sigla ? pedido.sigla : '-' }}
                  </span>
                  <span class="fixed-cell">
                    {{ pedido.nomeTurma }}
                  </span>
                  <span class="fixed-cell">
                    {{ pedido.nomeFornecedor }}
                  </span>
                  <span class="fixed-cell" >
                    <span tooltip="Data do Pedido">
                      <fa :icon="['fas', 'cart-circle-check']" class="icon"></fa>
                      {{ pedido.datapedido }}
                    </span>
                  </span>
                  <span class="fixed-cell">
                    <span tooltip="Previsão de Recebimento">
                      <fa :icon="['fas', 'calendar-star']" class="icon"></fa>
                      {{ pedido.entregaprevista }}
                    </span>
                  </span>
                  <span class="fixed-cell">
                    <span tooltip="Data do Recebimento" :class="['marker', pedido.entregaAtrasada && 'warning']">
                      <fa :icon="['fas', 'truck-ramp-box']" class="icon"></fa>
                      {{ pedido.entregaconfirmada === 1 ? pedido.dataentrega : 'Não recebido' }}
                    </span>
                  </span>
                  <span class="fixed-cell">
                    <span tooltip="Data da Entrega" :class="['marker', pedido.saidaconfirmada === 1 || pedido.datasaida != null ? 'success' : '']">
                      <fa :icon="['fas', 'person-carry-box']" class="icon"></fa>
                      {{ pedido.saidaconfirmada === 1 ? formatDate(pedido.datasaida) : 'Não entregue' }}
                    </span>
                  </span>
                  <!-- <span tooltip="Débitos Pedido" v-if="pedido.debitos_pedido">
                    <fa :icon="['fas', 'circle-dollar-to-slot']" class="icon error"></fa>
                    Inadimplente
                  </span>
                  <span tooltip="Débitos Mensalidade" v-if="pedido.debitos_mensalidades">
                    <fa :icon="['fas', 'money-check-dollar']" class="icon error"></fa>
                    Inadimplente
                  </span> -->
                  <!-- <span class="text-center fixed-cell">
                    {{ pedido.numeroNF }}
                  </span> -->
                  <div class="alerts">
                    <!-- <fa :icon="['fas', 'circle-dollar-to-slot']" :class="['icon','error']" title="Inadimplente - Débitos Pedido" v-if="pedido.debitos_pedido"/> -->
                    <fa :icon="['fas', 'money-check-dollar']" :class="['icon','error']" title="Inadimplente" v-if="pedido.bloqueiaPedido"/>

                    <a :href="pedido.danfe" target="_blank" :class="[pedido.danfe ? 'active' : 'lock']">
                      <fa :icon="['fas', 'file-invoice-dollar']" :class="['icon']" title="Nota Fiscal"/>
                    </a>
                    <a :href="pedido.etiqueta" target="_blank" :class="pedido.etiqueta ? 'active' : 'lock'">
                      <fa :icon="['fas', 'tag']" :class="['icon']" title="Etiqueta"/>
                    </a>
                    <!-- <fa :icon="['fas', 'person-carry-box']" :class="['icon']" title="Produto Entregue"/> -->
                    
                    <!-- <fa :icon="['fas', 'exclamation-triangle']" :class="['icon icon-error', { active: checkout.cartao == 0 && checkout.parcelas > checkout.transacoes.length }]"/>
                    <fa :icon="['fas', 'credit-card']" :class="['icon', { active: checkout.cartao }]"/>
                    <fa :icon="['fas', 'barcode-alt']" :class="['icon', { active: !checkout.cartao }]"/> -->
                  </div>
                  <div class="checkWrap" @mouseenter="preventParentClick = true" @mouseleave="preventParentClick = false" >
                    <input type="checkbox" class="check invert" v-model="selected" :value="pedido.idpedidomaterial" @change="trackFilterType()"/>
                  </div>
                  <transition name="slideRightToLeft">
                    <div class="listStamp" v-if="pedido.nomeLote && !pedido.collapse">
                      <fa :icon="['fas','calendar-range']" class="icon"></fa>
                      <b><p>{{ pedido.nomeLote }}</p></b>
                    </div>
                  </transition>
                </header>
                <transition name="slidedown-small">
                  <div class="checkoutDetails" v-if="pedido.collapse">
                    <input-spinner class="detailsSpinner" v-if="pedido.loadingDetails" />
                    <template v-else>
                      
                      <div class="checkoutDetailsLists">
                        <div>
                          <header>
                            <h5>Itens Pedido</h5>
                            <div>
                              <!-- <a href="#" class="btn btn-primary" @click.prevent="gerarNota(pedido)" v-if="!pedido.danfe && allowEmitirNota">
                                Gerar Nota Fiscal &nbsp;
                                <fa :icon="['fas', 'receipt']"/>
                              </a> -->
                              <a href="#" @click.prevent="confirmCancelPedido(pedido)" class="btn btn-primary">
                                Cancelar Pedido &nbsp;
                                <fa :icon="['fas','trash-can']"></fa>
                              </a>
                            </div>
                          </header>
                          <div v-if="pedido.nomeLote" class="cardsub">
                            <fa :icon="['fas','calendar-range']" class="icon"></fa>
                            <h6 class="color-text">{{ pedido.nomeLote }}</h6>
                          </div>
                          <table>
                            <thead>
                              <tr>
                                <th>Entregue</th>
                                <th>Item</th>
                                <th>Grade</th>
                                <th>Valor Unitário</th>
                                <th>Quantidade</th>
                                <th>Valor Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="item in pedido.items" :key="item">
                                <td>
                                  <div class="deliver">
                                    <fa :icon="['fas', 'clipboard-check']" :class="['icon', { active: item.dataEntrega }]" />
                                    <span v-if="item.dataEntrega">{{ formatDate(item.dataEntrega) }}</span>
                                    <span v-else>Não entregue</span>
                                  </div>
                                </td>
                                <td>{{ item.tipomaterial }}</td>
                                <td>{{ item.descricaoGrade }}</td>
                                <td>{{ formatMoney(item.valoritem) }}</td>
                                <td>{{ item.quantidade }}x</td>
                                <td>{{ formatMoney(item.somaValorItens) }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </template>
                  </div>
                </transition>
              </div>
            </card>
          </li>
        </ul>
        <transition name="">
          <div class="emptyMessage" v-if="pedidos.length == 0 && filtered">
            <span>
              <fa :icon="['fal','face-meh']" class="icon"/>
              <p>Não encontramos nada..</p>
            </span>
          </div>
        </transition>
        <template v-if="allowSave || allowPrintList">
          <div class="form-group col-md-12 form-actions actions-right">
            <template v-if="allowPrintList">
              <label class="check-control">
                <input type="checkbox" class="check" v-model="groupListItems">
                <span>Agrupar Pessoas</span>
              </label>
              <a href="#" @click.prevent="imprimirLista()" class="btn btn-primary" >Imprimir Lista</a>
            </template>
            <transition name="slideup-small">
              <a href="#" @click.prevent="receber()" class="btn btn-primary" v-if="filterType == 'naorecebido'">Receber Pedidos</a>
            </transition>
            <transition name="slideup-small">
              <a href="#" @click.prevent="entregar()" class="btn btn-primary" v-if="filterType == 'recebido'">Entregar Pedidos</a>
            </transition>
          </div>
        </template>
      </section>
      <confirm 
        :backdrop="false"
        class="confirm"
        ref="confirm"
      ></confirm>
    </div>
  </controller>
</template>

<script>
import MarketplaceService from '@/services/marketplace.service'
import UserService from '@/services/user.service'
import AcademicoService from '@/services/academico.service'
import { formatDate, formatMoney, createSelectOptions } from '@/services/utils.service'
import InputSpinner from '@/components/utilities/InputSpinner'
import moment from 'moment'

export default {
  components: {
    InputSpinner
  },
  watch: {
    selected ( value ) {
      this.allowSave = value.length > 0
      this.allowPrintList = value.length > 0
      console.log("allowSave:", this.allowSave)
    }
  },
  data () {
    return {
      loading: false,
      depositos: [],
      fornecedores: [],
      unidades: [],
      periodosLetivos: [],
      series: [],
      turmas: [],
      calendarios: [],
      filter: {
        nome: null,
        unidade: null,
        periodoLetivo: null,
        serie: null,
        turma: null,
        depositos: null,
        fornecedores: null,
        dataPedido: null,
        dataEntrega: null,
        dataPrevisaEntrega: null,
        recebido: null,
        faturado: null,
        calendario: null
      },
      wait: {
        depositos: false,
        fornecedores: false,
        unidades: false,
        calendarios: false
      },
      pedidos: [],
      backup: [],
      preventParentClick: false,
      processing: false,
      searchQuery: null,
      orderBy: 'default',
      orders: [
        {
          label: 'Ordenação Padrão',
          value: 'default'
        },
        { 
          label: 'ID Pedido A→B',
          value: 'idpedidomaterial-asc'
        },
        { 
          label: 'ID B→A',
          value: 'idpedidomaterial-desc'
        },
        { 
          label: 'Código Pedido A→B',
          value: 'codigo-asc'
        },
        { 
          label: 'Código Pedido B→A',
          value: 'codigo-desc'
        },
        { 
          label: 'Aluno A→B',
          value: 'aluno-asc'
        },
        { 
          label: 'Aluno B→A',
          value: 'aluno-desc'
        },
        { 
          label: 'Data A→B',
          value: 'datapedido-asc'
        },
        { 
          label: 'Data B→A',
          value: 'datapedido_unix-desc'
        },
        { 
          label: 'Previsão Entrega A→B',
          value: 'entregaprevista_unix-asc'
        },
        { 
          label: 'Previsão Entrega B→A',
          value: 'entregaprevista_unix-desc'
        },
      ],
      filterTypes: [
        {
          label: 'Ver Todos',
          value: 'all'
        },
        {
          label: 'Recebido (Para entregar para o aluno, utilize essa opção)',
          value: 'recebido'
        },
        {
          label: 'Não Recebido (Para receber na unidade, utilize essa opção)',
          value: 'naorecebido'
        },
        // {
        //   label: 'Aguardando Retirada',
        //   value: 'retirada'
        // },
        {
          label: 'Entregue',
          value: 'entregue'
        },
        {
          label: 'Não Entregue',
          value: 'naoentregue'
        },
        {
          label: 'Adimplente',
          value: 'adimplente'
        },
        {
          label: 'Inadimplente',
          value: 'inadimplente'
        },
        {
          label: 'Não Entregue e Adimplente',
          value: 'naoentregueadimplente'
        },
        {
          label: 'Não Entregue e Inadimplente',
          value: 'naoentregueinadimplente'
        },
      ],
      selected: [],
      filterType: "all",
      allowSave: false,
      todos: false,
      filtered: false,
      allowPrintList: false,
      printList: [],
      loadingPeriodos: false,
      loadingSeries: false,
      loadingTurmas: false,
      groupListItems: true,
      allowEmitirNota: false
    }
  },
  mounted () {
    this.getDepositos()
    this.getFornecedores()
    this.getUnidades()
    this.getCalendarios()

    this.loading = true
    window.spinner.emit("open")

    this.allowEmitirNota = UserService.filterPermissions('emitirNotaPedido')
  },
  methods: {
    tooltip () {
      const items = document.querySelectorAll('[tooltip]') 
      // console.log("items:", items)
      var tooltip
      const appendTooltip = e => {
        const target = e.target
        tooltip = document.createElement("div")
        const text = document.createTextNode(target.getAttribute('tooltip'))
        tooltip.appendChild(text)

        const bodyBounding = document.body.getBoundingClientRect()
        const bounding = target.getBoundingClientRect()
        const top = bounding.top - bodyBounding.top
        const left = bounding.left - bodyBounding.left

        tooltip.style.top = top + 'px'
        tooltip.style.left = left + 'px'
        tooltip.classList.add('tipper')

        const appendto = document.getElementsByTagName('main')[0]
              appendto.append(tooltip)
      }

      const removeTooltip = () => {
        tooltip.remove()
      }

      items.forEach( k => {
        k.addEventListener('mouseenter', appendTooltip)
        k.addEventListener('mouseleave', removeTooltip)
      })
    },
    getDepositos () {
      MarketplaceService.getDepositos().then(
        response => {
          // console.log("response:", response.data)
          this.depositos = createSelectOptions(response.data, 'iddepositomaterial' ,'descricao')
          this.wait.depositos = true
          this.stopSpinner()

        },
        error => {
          console.error(error)
        }
      )
    },
    getFornecedores () {
      MarketplaceService.getFornecedores().then(
        response => {
          // console.log("response:", response.data)
          this.fornecedores = createSelectOptions(response.data, 'idFornecedor' ,'nome')
          this.wait.fornecedores = true
          this.stopSpinner()
        },
        error => {
          console.error(error)
        }
      )
    },
    getUnidades () {
      UserService.getMinhasUnidades().then(
        response => {
          console.log("getMinhasUnidades:", response.data)
          this.unidades = createSelectOptions(response.data, 'idUnidade' ,'descricao')
          this.wait.unidades = true
          this.stopSpinner()
        },
        error => {
          console.error(error)
        }
      )
    },
    getPeriodosLetivos () {
      this.loadingPeriodos = true
      AcademicoService.getPeriodosLetivos(this.filter.unidade).then(
        response => {
          console.log("getPeriodosLetivos:", response.data)
          this.periodosLetivos = createSelectOptions(response.data, 'idPeriodoLetivo' ,'descricao')

          this.loadingPeriodos = false
        },
        error => {
          console.error(error)
          this.loadingPeriodos = false
        }
      )
    },
    getSeries () {
      this.loadingSeries = true
      AcademicoService.getSeries(this.filter.periodoLetivo, 0).then(
        response => {
          // console.log("getSeries:", response.data)

          if ( response.data.length > 0 ) {
            response.data.map( k => {
              k.label = k.descricao + ' ' + k.descricaoTurno
            })
            this.series = createSelectOptions(response.data, 'idSerie' ,'label')
            this.loadingSeries = false
          }
        },
        error => {
          console.error(error)
          this.loadingSeries = false
        }
      )
    },
    getTurmas () {
      this.loadingTurmas = true
      AcademicoService.getTurmas(this.filter.serie).then(
        response => {
          // console.log("getTurmas:", response.data)
          this.turmas = createSelectOptions(response.data, 'idTurma' ,'descricao')
          this.loadingTurmas = false
        },
        error => {
          console.error(error)
          this.loadingTurmas = false
        }
      )
    },
    getCalendarios () {
      this.loadingTurmas = true
      MarketplaceService.getCalendarios().then(
        response => {
          console.log("getCalendarios:", response.data)
          this.calendarios = createSelectOptions(response.data, 'idcalendarioentrega' ,'descricao')
          this.loadingTurmas = false

          this.wait.calendarios = true
          this.stopSpinner()
        },
        error => {
          console.error(error)
          this.loadingTurmas = false
        }
      )
    },
    runFilter () {     
      const empty = Object.values(this.filter).every(e => !e)
      
      if ( empty ) {
        window.toaster.emit('open', {style: "warning", message: "Preencha ao menos um campo, para filtrar.", floater: false})
      } else {
        this.filtered = false;
        this.loading = true
        this.selected = []
        this.filterType = 'all'

        window.spinner.emit("open")

        console.log("this.filter:", this.filter)
        MarketplaceService.filterPedidos(this.filter).then(
          response => {
            if (response.data.length > 0 ) {
              console.log("pedidos:", response.data)

              response.data.map ( k => {
                k.collapse = false
                k.loadingDetails = false
                k.data = {}
                k.datapedido_unix = moment(k.datapedido, 'DD/MM/YYYY').unix()
                k.entregaprevista_unix = moment(k.entregaprevista, 'DD/MM/YYYY').unix()
                k.dataentrega_unix = k.dataentrega ? moment(k.dataentrega, 'DD/MM/YYYY').unix() : '...'

                const descricao = k.descricao.split(" - ")
                const codPedido = descricao[0]
                const nomeAluno = descricao[1]

                k.codigo = codPedido
                k.aluno = nomeAluno

                const previsao = moment(k.entregaprevista, 'DD/MM/YYYY')
                const today = moment()
                const diff = today.diff(previsao, 'days')

                k.entregaAtrasada = diff > 0 && k.entregaconfirmada !== 1
                
                this.filtered = true
              })

              this.pedidos = response.data

              this.cloneData()
              
              console.log("this.pedidos:", this.pedidos)
              // window.pagescroll.emit('goto', 500)
              
              setTimeout(() => {
                this.tooltip()
                this.orderBy = 'default'
                this.filterType = 'all'
              },500)
            } else {
              this.pedidos = []          
              window.toaster.emit('open', {style: "warning", message: "Ops. Não econtramos pedidos com os dados inseridos.", floater: false})
            }
            
            window.spinner.emit("close")
            this.loading = false

          },
          error => {
            console.error(error)
            window.spinner.emit("close")
            this.loading = false
          }
        )
      
      }
      
    },
    toggleCollapse ( collapse, index, item, checkout, force ) {
      const target = this.pedidos[index]

      if ( !target.collapse && collapse || force ) {
        this.reload = {
          collapse: collapse,
          index: index,
          item: item,
          checkout: checkout
        }
        console.log("item:", item)
        // console.log("target:", target)
        // console.log("checkout:", checkout)
        target.collapse = collapse
        target.loadingDetails = true
        
        const calendario = item.idcalendarioentrega ? item.idcalendarioentrega : 0
        console.log('calendario:', calendario)

        MarketplaceService.getItemsPedidos(item.idpedidomaterial, item.idAluno, item.idFornecedor, calendario).then(
          response => {
            target.loadingDetails = false
            target.items = response.data
            // target.data.transacoes = response.data.transacoes

            console.log("getItemsPedidos:", response)
          },
          error => {
            console.error('error:', error.response)
            target.loadingDetails = false
          }
        )
      } else {
        target.collapse = collapse
      }
    },
    clear () {
      this.filter = {
        nome: null,
        unidade: null,
        periodoLetivo: null,
        serie: null,
        turma: null,
        depositos: null,
        fornecedores: null,
        dataPedido: null,
        dataEntrega: null,
        dataPrevisaEntrega: null,
        recebido: null,
        faturado: null
      }
      this.periodosLetivos = []
      this.series = []
      this.turmas = []
    },
    stopSpinner () {
      const done = !Object.values(this.wait).includes(false)

      if ( done ) {
        window.spinner.emit("close")
        this.loading = false
      }
    },
    cloneData () {
      const pedidos = []

      this.pedidos.map( k => {
        pedidos.push(k)
      })

      this.backup = pedidos
    },
    searchList () {
      const query = this.searchQuery?.toLowerCase()
      
      if ( query && query.length > 3 ) {
        console.log("query:", query)
        // const filtered = asArray.filter(([key, value]) => typeof value === 'string')
        const filtered = this.pedidos.filter( k => {
          const descricao = k.descricao.toLowerCase()
          const previsao = k.entregaprevista
          const lote = k.nomeLote.toLowerCase()
          return descricao.includes(query) || previsao.includes(query) || lote.includes(query)
        })

        this.pedidos = filtered
        console.log("filtered:", filtered)
        // const justStrings = Object.fromEntries(filtered)

      } else {
        console.log("this.backup", this.backup)
        this.pedidos = this.backup
      }
    },
    sortList () {
      this.processing = true
      this.cloneData()

      // console.log("orderBy:", this.orderBy)
      
      if ( this.orderBy === 'default' ) {
        this.pedidos = this.backup
      } else {
        const by = this.orderBy.split("-")[0]
        const order = this.orderBy.split("-")[1]

        var sorted = order == 'asc' ? this.pedidos.sort((a,b) => (a[by] > b[by]) ? 1 : ((b[by] > a[by]) ? -1 : 0)) : this.pedidos.sort((a,b) => (a[by] > b[by]) ? -1 : ((b[by] > a[by]) ? 1 : 0))
        // console.log('sorted:', sorted)

        this.pedidos = sorted

        setTimeout(() => {
          this.processing = false
        },500)
      }
      
      // if ( order === 'asc') {
      //   this.pedidos.sort((a,b) => (a[target] > b[target]) ? 1 : ((b[target] > a[target]) ? -1 : 0))
      // } else if ( order === 'desc' ) {
      //   this.pedidos.sort((a,b) => (a[target] > b[target]) ? -1 : ((b[target] > a[target]) ? 1 : 0))
      // }
      
    },
    filterList () {
      // console.log(e.target.checked)
      // console.log(e.target.value)
      console.log('filterType:', this.filterType)

      var filtered
      this.pedidos = this.backup
      this.selected = []
      this.todos = false
      
      if ( this.filterType === 'recebido' ) {
        filtered = this.pedidos.filter( k => {
          return k.entregaconfirmada == 1 && ( k.saidaconfirmada == 0 || !k.saidaconfirmada )
        })
      }
      if ( this.filterType === 'naorecebido' ) {
        filtered = this.pedidos.filter( k => {
          return ( k.entregaconfirmada == 0 || !k.entregaconfirmada ) &&  ( k.saidaconfirmada == 0 || !k.saidaconfirmada )
        })
      }
      // if ( this.filterType === 'retirada' ) {
      //   filtered = this.pedidos.filter( k => {
      //     return k.entregaconfirmada == 1 && !k.saidaconfirmada
      //   })
      // }
      if ( this.filterType === 'entregue' ) {
        filtered = this.pedidos.filter( k => {
          return k.saidaconfirmada == 1
        })
      }
      if ( this.filterType === 'naoentregue' ) {
        filtered = this.pedidos.filter( k => {
          return k.saidaconfirmada == 0 || !k.saidaconfirmada
        })
      }
      if ( this.filterType === 'adimplente' ) {
        filtered = this.pedidos.filter( k => {
          return k.debitos_mensalidades == 0 && ( k.bloqueiaPedido == 0 || !k.bloqueiaPedido )
        })
      }
      if ( this.filterType === 'inadimplente' ) {
        filtered = this.pedidos.filter( k => {
          return k.bloqueiaPedido == 1
        })
      }
      if ( this.filterType === 'naoentregueadimplente' ) {
        filtered = this.pedidos.filter( k => {
          // console.log("k:", k)
          return ( k.saidaconfirmada == 0 || !k.saidaconfirmada ) && ( !k.bloqueiaPedido || k.bloqueiaPedido == 0 )
        })
      }
      if ( this.filterType === 'naoentregueinadimplente' ) {
        filtered = this.pedidos.filter( k => {
          // console.log("k:", k)
          return ( k.saidaconfirmada == 0 || !k.saidaconfirmada ) && k.bloqueiaPedido == 1
        })
      }
      if ( this.filterType == 'all' ) {
        filtered = this.backup
      }
      // filtered = filtered.length == 0 ? this.backup : filtered

      // console.log('filtered:', filtered)
      this.pedidos = filtered
    },
    receber () {
      // console.log("this.selected", this.selected)
        
      MarketplaceService.disponivelUnidade(this.selected).then(
        response => {
          console.log("disponivelUnidade:", response.data)
          this.runFilter()
        },
        error => {
          console.error("response.error:", error)
        }
      )
    },
    entregar () {
      MarketplaceService.retiradoUnidade(this.selected).then(
        response => {
          console.log("retiradoUnidade:", response.data)
          this.runFilter()
        },
        error => {
          console.error("response.error:", error)
        }
      )
    },
    formatDate ( value ) {
      return formatDate(value)
    },
    formatMoney ( value ) {
      return formatMoney(value)
    },
    selectAll ( e ) {
      if ( e.target.checked ) {
        var selected = []

        this.pedidos.map( k => {
          selected.push(k.idpedidomaterial)
        })

        this.selected = selected
      } else {
        this.selected = []
      }
    },
    trackFilterType () {
      // 
    },
    imprimirLista () {
      // console.log("this.pedidos:", this.pedidos)
      // console.log("this.selected:", this.selected)
      this.printList = this.pedidos.filter( k => {
        return this.selected.includes(k.idpedidomaterial)
      })
      
      localStorage.setItem('PrintList', JSON.stringify(this.printList))
      
      // console.log("data:", data)
      // this.$router.push({name:'Lista Entrega'})
      const compact = this.groupListItems
      console.log("compact:", compact)

      window.open('/marketplace/lista-entrega?compact=' + compact,'_blank')
    },
    confirmCancelPedido ( pedido ) {
      this.$refs.confirm.run({
        message: "Tem certeza que cancelar este pedido?",
        confirmButton: "Sim",
        denyButton: "Não",
        data: pedido,
        callback: this.cancelPedido,
      })
    },
    cancelPedido ( response, pedido ) {
      console.log("cancelar pedido:", pedido);
      
      if ( response ) {
        window.spinner.emit("open")
        MarketplaceService.cancelaPedido(pedido.idpedidomaterial).then(
          response => {
            if ( response.status == 200 ) {
              this.runFilter()
              window.toaster.emit('open', {style: "success", message: 'Pedido cancelado com sucesso.', floater: true})
            }
            window.spinner.emit("close")
          },
          () => {
            window.toaster.emit('open', {style: "warning", message: 'Ops! Algo aconteceu. Não foi possível cancelar o pedido selecionado.', floater: true})
            window.spinner.emit("close")
          }
        )
      }
    },
    gerarNota ( pedido ) {
      console.log("gerarNota:", pedido)
      const idAluno = pedido.idAluno
      const idcheckoutMarketplace = pedido.idCheckoutMarketplace

      window.spinner.emit("open")
      MarketplaceService.gerarPedido(idAluno, idcheckoutMarketplace, 1).then(
        response => {
          console.log("response:", response)
          if ( response.status == 200 ) {
            window.toaster.emit('open', {style: "success", message: "Tudo certo! Solicitação enviada com sucesso. O Processo pode demorar alguns minutos.", floater: true})
          }

          window.spinner.emit("close")
        },
        () => {
          window.toaster.emit('open', {style: "warning", message: "Ops. Algo aconteceu. Não foi possível gerar o pedido.", floater: true})
          window.spinner.emit("close")
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
    .checkoutDetails {
    width: 100%; border-top: 1px solid $color-bg;
    padding-top: $hmg; margin-top: $hmg;
    @extend %transition_4e;

    .detailsSpinner {
      margin: auto;
    }

    > div {
      display: flex; align-content: stretch; align-items: stretch;

      &.checkoutDetailsLists {
        header {
          display: flex;
          align-items: center; align-content: center;
          justify-content: space-between;
          margin-bottom: $hmg;

          > div {
            align-items: center; align-content: center;
            justify-content: flex-end;
            .btn {
              margin-left: $hmg_small
            }
          }
        }

        > div {
          &:first-child {
            width: 100%;
          }

          // &:last-child {
          //   flex: 2
          // }

          ul {
            width: 100%; height: auto;
            padding: 0; margin: 0;

            >li {
              width: 100%; list-style: none;

              >div:not(.splitRules) {
                display: flex; align-content: center; align-items: center;
                justify-content: space-between;
                padding: $hmg_mini 0; border-bottom: 1px solid $color-light;
                margin: 0;

                > span:last-child {
                  text-align: right;
                }
              }
              
              .student {
                display: flex; align-content: center; align-items: center;
                width: 100%; background-color: $color-bg; padding: $hmg_mini;
                // border-bottom-left-radius: 1em; border-bottom-right-radius: 1em;
                margin-bottom: $hmg_mini; font-size: 12px; line-height: 1;
                
                p {padding: 0 0 0 $hmg_mini; margin: 0; font-size: 11px; line-height: 1} 
              }
            }

            &.fixed {
              li {
                white-space: nowrap;
              }
            }
          }

          ol {
                padding: 0 0 0 34px; margin: 0 0 $hmg_small 0;
                border-bottom: 2px solid $color-light;

                li {
                  width: 100%;
                  list-style: decimal; padding: 3px 0;
                  margin: 0; position: relative;

                  > div:not(.deliver) {
                    justify-content: space-between; width: 100%;
                    display: flex; align-content: center; align-items: flex-start;

                    > span {
                      &:first-child {
                        white-space: normal;
                      }
                    }
                  }

                  
                }
              }
        }

        table {
          width: 100%;
          border-collapse: collapse;
          border: 0;
          thead {
            th {
              padding: .4em;
              border-bottom: 2px solid $color-light
            }
          }
          tbody {
            tr {
              @extend %transition_3o;

              td {
                padding: .4em;
                border-bottom: 1px solid $color-light;

                &:first-child{
                  padding-left: $mg_mini;
                }

                a {
                  display: inline-block;
                  margin-left: $mg_mini;

                  &:first-child{margin-left: 0;}
                  &:hover{
                    color: $color-primary
                  }
                }
              }

              &:hover:not(.totais) {
                background-color: $color-bg;
              }

              &.over {
                background-color: $color-alert-light;

                &:hover {
                  background-color: $color-alert-light-hover;
                }
              }
              &.cancelado {
                td {
                  opacity: .4;
                }
              }
            }
          }
          tfoot {
            th {
              padding: $hmg_mini;
              font-size: 13px;
              border-top: 1px solid $color-bg;
              border-bottom: 1px solid $color-bg;
              
              a {
                .icon {
                  transform-origin: center;
                  @extend %transition_4e;
                }
                
                &:hover { 
                  color: $color-primary;
                  .icon{
                    transform: scale(1.1);
                  }
                }
              }
            }
          }
        }

        .tableSpinner {
          width: 100%; height: 300px; 
          display: flex; align-content: center; align-items: center; justify-content: center;
          background-color: $color-bg;
        }

        .deliver {
          // position: absolute; top: 4px; left: -30px;
          // z-index: 100;

          .icon {
            color: $color-bg; font-size: 12px;

            &.active {
              color: $color-success-2
            }
          }

          span {
            line-height: 1; padding: 4px 8px;
            // position: absolute;
            // border-radius: 1em;
            // background-color: $color-success-2;
            // color: $color-secondary;
            right: 0; top: 50%;
            // box-shadow: 1px 2px 4px rgba(16, 122, 73, 0.3);
            opacity: 0; white-space: nowrap;
            @extend %transition_4e;
            // pointer-events: none;
          }

          // &:hover {
          //   span {
          //     transform: translate(105%, -50%); opacity: 1;
          //     pointer-events: all;
          //   }
          // }
        }
      }
    }
  }

  .emptyMessage {
    .icon {
      font-size: 44px;
      margin-bottom: $hmg_mid;
    }
  }

  .cardsub {
    display: flex; align-content: center !important; align-items: center !important;
    margin-bottom: $hmg_small;

    h6 {
      margin: 0; padding: 0;
    }
    .icon {
      margin-right: $hmg_mini
    }
  }
  
  .listStamp {
    display: flex; align-content: center; align-items: center;
    position: absolute; bottom: 0; left: 66px;
    transform: translateY($hmg_mini);
    background-color: $color-bg;
    border-radius: $border-radius-small;
    padding: $hmg_mini;
    font-size: $font-size-mini;
    line-height: 1;
    @extend %transition_4e;

    p {
      margin: 0; padding: 0;
    }
    .icon {
      margin-right: $hmg_mini
    }
  }

  .icon.error {
    font-size: 16px !important; color: $color-alert !important
  }

  .sep {
    display: block;
    width: 1px; height: 24px;
    background-color: $color-primary;
    transform: translateY(-3px);
    margin: 0 .4em;
  }
</style>