<script setup>
  import UserService from '@/services/user.service.js'
  import { createSelectOptions } from '@/services/utils.service.js'
</script>

<template>
  <controller>
    <page-header title="Locais" />
    <section class="frame">
      <div class="row">
        <div class="col-md-4">
          <select-control v-model="filter.unidade" :options="options.unidades" placeholder="Selecione" />
        </div>
      </div>
    </section>
  </controller>
</template>

<script>
export default {
  data () {
    return {
      options: {
        unidades: []
      },
      filter: {
        unidade: null
      }
    }
  },
  mounted () {
    this.getUnidades()
  },
  methods: {
    getUnidades () {
      UserService.getUnidades().then(
        response => {

          this.options.unidades = createSelectOptions(response.data, 'idUnidade', 'descricao')
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops! Algo aconteceu. Não foi possível coletar as unidades.", floater: true})
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>

</style>