import axios from 'axios'
import authHeader from './auth-header'
// import moment from 'moment'

class MarketplaceService {
  getFormasPagamento( idFormaPagamento ) {
    if ( idFormaPagamento ) {
      //
    } else {
      return axios.get(process.env.VUE_APP_API_URL + 'descontoloja/get/formasdepagamento/all', { headers: authHeader() })
    }
  }

  getDescontoListById ( idDesconto ) {
    return axios.get(process.env.VUE_APP_API_URL + 'descontoloja/get/descontolistabyid/' + idDesconto, { headers: authHeader() })
  }

  deleteDescontosListaById ( idDesconto ) {
    return axios.get(process.env.VUE_APP_API_URL + 'descontoloja/deletedescontobyid/' + idDesconto, { headers: authHeader() })
  }

  getCheckoutsMarketplace ( busca ) {
    const query = busca ? busca : 0
    return axios.get(process.env.VUE_APP_API_URL + 'checkoutmarketplace/listacheckouts/' + query, { headers: authHeader() })
  }

  getCheckoutDetails ( id ) {
    return axios.post(process.env.VUE_APP_API_URL + 'checkoutmarketplace/edit/chekoutmp/' + id, {},  { headers: authHeader() })

    // return axios.post(process.env.VUE_APP_API_URL + 'buscaprodutosloja', data, { headers: authHeader() })
  }

  getFormasPagamentoFree () {
    return axios.get(process.env.VUE_APP_API_URL + 'checkoutmarketplace/getformaspagamento',  { headers: authHeader() })

    // return axios.post(process.env.VUE_APP_API_URL + 'buscaprodutosloja', data, { headers: authHeader() })
  }

  saveRecompra ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'checkoutmarketplace/editsave', data,  { headers: authHeader() })
  }
  
  filterVendasTransacoes ( data ) {
    if ( data ) {
      const s = '/'
      const idVendaMaterial = data.idVendaMaterial ? data.idVendaMaterial : 0
      const idTransacao = data.idTransacao ? data.idTransacao : 0
      const idUnidade = data.idUnidade ? data.idUnidade : 0
      const idAluno = data.idAluno ? data.idAluno : 0
      const idPessoaResponsavel = data.idPessoaResponsavel ? data.idPessoaResponsavel : 0
      const pago = data.pago ? data.pago : 0
      const status = data.status ? data.status : 0
      const cancelado = data.cancelado ? data.cancelado : 0
      const dataPgtoDe = data.pagoemDe ? data.pagoemDe : 0
      const dataPgtoAte = data.pagoemAte ? data.pagoemAte : 0
      const dataVencDe = data.vencimentoDe ? data.vencimentoDe : 0
      const dataVencAte = data.vencimentoAte ? data.vencimentoAte : 0
      const nome = data.nome ? data.nome : 0
      const idTransacaoMeioPagamento = data.idTransacaoMeioPagamento ? data.idTransacaoMeioPagamento : 0

      return axios.get(process.env.VUE_APP_API_URL + 'transacoesvendas/mp/' + idVendaMaterial + s + idTransacao + s + idUnidade + s + idAluno + s + idPessoaResponsavel + s + pago + s + cancelado + s 
                                                                            + dataPgtoDe + s + dataPgtoAte + s + dataVencDe + s + dataVencAte + s + status + s + nome + s + idTransacaoMeioPagamento, 
                                                                            { headers: authHeader() })
    }
    
  }

  getVendasTransacoesDetails ( idCheckout ) {
    return axios.get(process.env.VUE_APP_API_URL + 'gettransacoesvendas/mp/' + idCheckout,  { headers: authHeader() })
  }

  editTransacao ( transacaoAntiga, transacaoNova ) {

    var drop = {}
    if ( transacaoAntiga ) drop.antiga = transacaoAntiga
    drop.nova = transacaoNova
    
    console.log("drop:", drop)
    return axios.post(process.env.VUE_APP_API_URL + 'edittransacoesvendas/mp', drop,  { headers: authHeader() })
  }

  removeTransacao ( idTransacao, obs ) {
    const data = {
      obs: obs
    }
    return axios.post(process.env.VUE_APP_API_URL + 'transacaovenda/' + idTransacao, data, { headers: authHeader() })
  }

  // PEDIDOS ----------------------------------------------------------------------------

  getDepositos () {
    return axios.get(process.env.VUE_APP_API_URL + 'depositosmaterial',  { headers: authHeader() })
  }

  getFornecedores () {
    return axios.get(process.env.VUE_APP_API_URL + 'fornecedores',  { headers: authHeader() })
  }

  getCalendarios () {
    return axios.get(process.env.VUE_APP_API_URL + 'calendario/entrega/list',  { headers: authHeader() })
  }

  filterPedidos ( data ) {
    const s = '/'
    const idDeposito = data.deposito ? data.deposito : 0
    const idFornecedor = data.fornecedores ? data.fornecedores : 0
    const faturado = data.faturado ? data.faturado : 0
    const recebido = data.recebido ? data.recebido : 0
    const numeroNF = data.notaFiscal ? data.notaFiscal : 0
    const dataPedido = data.dataPedido ? data.dataPedido : 0
    const dataEntrega = data.dataEntrega ? data.dataEntrega : 0
    const nome = data.nome ? data.nome : 0
    const entregaPrevista = data.dataPrevisaEntrega ? data.dataPrevisaEntrega : 0
    const idUnidade = data.unidade ? data.unidade : 0
    const idPeriodoLetivo = data.periodoLetivo ? data.periodoLetivo : 0
    const idSerie = data.serie ? data.serie : 0
    const idTurma = data.turma ? data.turma : 0
    const idCalendario = data.calendario ? data.calendario : 0
    // {idDeposito?}/{idFornecedor?}/{faturado?}/{recebido?}/{numeroNF?}/{dataPedido?}/{dataEntrega?}/{nome?}/{entregaPrevista?}

    return axios.get(process.env.VUE_APP_API_URL + 'pedidosmaterial' + s + idDeposito + s + idFornecedor + s + faturado + s + recebido
                                                 + s + numeroNF + s + dataPedido + s + dataEntrega + s + nome + s + entregaPrevista + s
                                                 + idUnidade + s + idPeriodoLetivo + s + idSerie + s + idTurma + s + idCalendario,  { headers: authHeader() })                                           
  }

  getItemsPedidos ( pedido, idAluno, idFornecedor, idCalendario ) {
    
    return axios.get(process.env.VUE_APP_API_URL + 'itenspedido/' + pedido + '/' + idAluno + '/' + idFornecedor + '/' + idCalendario, { headers: authHeader() })
  }

  disponivelUnidade ( pedidos ) {
    const idsPedidos = {
      idsPedidos: pedidos
    }
    return axios.post(process.env.VUE_APP_API_URL + 'pedidomaterial/entrega/unidade', idsPedidos, { headers: authHeader() })
  }

  retiradoUnidade ( pedidos ) {
    const idsPedidos = {
      idsPedidos: pedidos
    }
    return axios.post(process.env.VUE_APP_API_URL + 'pedidomaterial/entrega/pedido/aluno', idsPedidos, { headers: authHeader() })
  }

  getMeusPedidos ( idResponsavel ) {
    return axios.get(process.env.VUE_APP_API_URL + 'itempedido/meuspedidos/' + idResponsavel, { headers: authHeader() })
  }

  getMeusBoletos () {
    return axios.get(process.env.VUE_APP_API_URL + 'cobranca/get/boletos/mp', { headers: authHeader() })
  }

  cancelarCompra ( data ) {
    // data example
    // {
    //   "idsItens": [1424897,1424898],
    //   "idsVendas": [],
    //   "motivoEstorno": "Cliente comprou errado.",
    //   "bank_account": {
    //     "bank_code": "341",
    //     "agencia": "0767",
    //     "agenciaDV": null,
    //     "conta": "46816",
    //     "contaDV": "8"
    //   }
    // }
    return axios.post(process.env.VUE_APP_API_URL + 'pedidomaterial/cancelamento/estorno/' + data.idcheckoutMarketplace, data, { headers: authHeader() })
  }

  cancelaPedido ( pedido ) {
    // /pedidomaterial/cancelar/pedido/{idPedidoMaterial}
    return axios.delete(process.env.VUE_APP_API_URL + 'pedidomaterial/cancelar/pedido/' + pedido, { headers: authHeader() })
  }

  postback ( id ) {
    const postback = {
      id: id.toString(),
      postback_id: 'po_ckyxet0fh4sqs01k1hels6gyf',
      model: 'transactions'
    }
    
    const headers = {
      'Access-Control-Allow-Origin': '*', 
      'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
    }
    return axios.post(process.env.VUE_APP_API_URL_PUBLIC + 'postback', postback, { headers: headers }) 
  }

  gerarPedido (idAluno, idcheckoutMarketplace, emiteNota) {
    emiteNota = emiteNota ? 1 : 0
    return axios.post(process.env.VUE_APP_API_URL + `pedidomaterial/create/${idAluno}/${idcheckoutMarketplace}/0/0/0/${emiteNota}`, {}, { headers: authHeader() })
  }

  gerarPedidosRetroativos ( data ) {
    const idCheckoutMP = data.idCheckoutMP
    const pedido = data.pedido
    const nota = data.nota
    const recebimento = data.recebimento
    const entrega = data.entrega

    return axios.post(process.env.VUE_APP_API_URL + `loja/gerar/pedidos/md/full/${idCheckoutMP}/${pedido}/${nota}/${recebimento}/${entrega}`, {}, { headers: authHeader() })
  }

  // PARAMETRIZAÇÃO GET ----------------------------------------------------------------------------

  getParams ( src ) {
    return eval('this.' + src + '()')
  }

  tipoMaterial () {
    return axios.get(process.env.VUE_APP_API_URL + 'tiposmaterial', { headers: authHeader() })
  }
  itemMaterial () {
    return axios.get(process.env.VUE_APP_API_URL + 'itensmaterial', { headers: authHeader() })
  }
  calendariosentrega () {
    return axios.get(process.env.VUE_APP_API_URL + 'calendariosentrega', { headers: authHeader() })
  }
  series ( options ) {
    const idTurno = options.idTipoCurso ? options.idTipoCurso : 0
    const idCurso = options.idCurso ? options.idCurso : 0
    const idPeriodoLetivo = options.idPeriodoLetivo ? options.idPeriodoLetivo : 0
    const idTipoCurso = options.idTipoCurso ? options.idTipoCurso : 0

    return axios.get(process.env.VUE_APP_API_URL + 'series/' + idTurno + '/' + idCurso + '/' + idPeriodoLetivo + '/' + idTipoCurso, { headers: authHeader() })
  }

  getComposicaoMaterial ( options ) {
    const idTipoMaterial = options.idTipoMaterial ? options.idTipoMaterial : 0
    const idItemMaterial = options.idItemMaterial ? options.idItemMaterial : 0
    const idCalendarioEntrega = options.idCalendarioEntrega ? options.idCalendarioEntrega : 0
    const idCalendarioAplicacao = options.idCalendarioAplicacao ? options.idCalendarioAplicacao : 0

    return axios.get(process.env.VUE_APP_API_URL + 'composicoesmaterial/' + idTipoMaterial + '/' + idItemMaterial + '/' + idCalendarioEntrega + '/' + idCalendarioAplicacao, { headers: authHeader() })
  }
  saveComposicaomaterial ( options ) {
    return axios.post(process.env.VUE_APP_API_URL + 'composicaomaterial', options, { headers: authHeader() })
  }
  updateComposicaomaterial ( idComposicaoMaterial, options ) {
    return axios.put(process.env.VUE_APP_API_URL + 'composicaomaterial/' + idComposicaoMaterial, options, { headers: authHeader() })
  }
  deleteComposicaomaterial ( idComposicaoMaterial ) {
    return axios.delete(process.env.VUE_APP_API_URL + 'composicaomaterial/' + idComposicaoMaterial, { headers: authHeader() })
  }

  getTipoMaterial () {
    return axios.get(process.env.VUE_APP_API_URL + 'tiposmaterial', { headers: authHeader() })
  }

  getCategorias () {
    return axios.get(process.env.VUE_APP_API_URL + 'loja/categoria/list', { headers: authHeader() })
  }
  getSubcategorias ( categoria ) {
    categoria = categoria ? categoria : 0

    return axios.get(process.env.VUE_APP_API_URL + 'loja/subcategorias/list/' + categoria, { headers: authHeader() })
  }

  getSegmentosPermitidos ( idTipoMaterial ) {
    if ( idTipoMaterial ) {
      return axios.get(process.env.VUE_APP_API_URL + 'materialseguimento/bytipomaterial/' + idTipoMaterial, { headers: authHeader() })
    } else {
      return axios.get(process.env.VUE_APP_API_URL + 'materialseguimento', { headers: authHeader() })
    }
  }
  
  saveMaterialSegmento ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + `materialseguimento`, data, { headers: authHeader() })
  }

  deleteMaterialSegmento ( idMaterialSegmento ) {
    return axios.delete(process.env.VUE_APP_API_URL + `materialseguimento/${idMaterialSegmento}`, { headers: authHeader() })
  }

  getSegmentoTurmas ( idMaterialSegmento ) {
    if ( idMaterialSegmento ) {
      return axios.get(process.env.VUE_APP_API_URL + `tipomaterialturmas/getmaterialturmas/${idMaterialSegmento}`, { headers: authHeader() })
    } else {
      return axios.get(process.env.VUE_APP_API_URL + 'tiposmaterialturmas', { headers: authHeader() })
    }
  }
  
  saveMaterialTurmas ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + `tipomaterialturmas`, data, { headers: authHeader() })
  }

  deleteMaterialTurmas ( idMaterialTurmas ) {
    return axios.delete(process.env.VUE_APP_API_URL + `tipomaterialturmas/${idMaterialTurmas}`, { headers: authHeader() })
  }
  
  filterProdutos ( options ) {
    const descricao = options.descricao ? options.descricao : 0
    const ativo = options.ativo ? 1 : 0
    const matriculainicial = options.matriculainicial ? options.matriculainicial : 0
    const matriculafinal = options.matriculafinal ? options.matriculafinal : 0
    const iniciovenda = options.iniciovenda ? options.iniciovenda : 0
    const terminovenda = options.terminovenda ? options.terminovenda : 0
    const idcategorialoja = options.idcategorialoja ? options.idcategorialoja : 0
    const idsubcategoria = options.idsubcategoria ? options.idsubcategoria : 0
    const servicoextra = options.servicoextra ? 1 : 0
    const precoitem = options.precoitem ? 1 : 0

    return axios.get(process.env.VUE_APP_API_URL + 'loja/lista/tipomaterial/'+ descricao + '/'+ ativo + '/' + matriculainicial + '/' + matriculafinal + '/' + iniciovenda + '/' + terminovenda + '/' + idcategorialoja + '/' + idsubcategoria + '/' + servicoextra + '/' + precoitem, { headers: authHeader() })
  }

  filterFormasPagamentoMaterial ( descricao ) {
    return axios.get(process.env.VUE_APP_API_URL + `proximociclo/get/formapagamentomaterial/${descricao}`, { headers: authHeader() })
  }

  saveTipoMaterial ( options ) {
    options.ativo = options.ativo ? 1 : 0
    options.servicoextra = options.servicoextra ? 1 : 0
    options.precoitem = options.precoitem ? 1 : 0
    options.nao_comunica_venda = options.nao_comunica_venda ? 1 : 0
    options.instrucaocancelamento = 0
    options.controlaestoque = options.controlaestoque ? 1 : 0
    options.idcategorialoja = 0

    if ( options.descricaoCategoria ) delete options.descricaoCategoria
    if ( options.descricaoSubCategoria ) delete options.descricaoSubCategoria

    if ( options.idtipomaterial ) {
      return axios.put(process.env.VUE_APP_API_URL + 'tipomaterial/' + options.idtipomaterial, options, { headers: authHeader() })
    } else {
      return axios.post(process.env.VUE_APP_API_URL + 'tipomaterial', options, { headers: authHeader() })
    }
  }

  uploadImagemProduto ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'tipomaterial/uploadimagemproduto', data, { headers: authHeader() })
  }

  getSubcategoriasEFormasPagamento ( tipomaterial ) {
    return axios.get(process.env.VUE_APP_API_URL + 'tipomaterial/get/formapagamento/subcategorias/' + tipomaterial, { headers: authHeader() })
  }

  saveSubcategoriasEFormasPagamento ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'tipomaterial/atualizar/formapagamento/subcategorias', data, { headers: authHeader() })
  }

  saveFornecedor ( data ) {
    delete data.uf
    
    if ( data.idFornecedor ) {
      return axios.put(process.env.VUE_APP_API_URL + 'fornecedor/' + data.idFornecedor, data, { headers: authHeader() })
    } else {
      return axios.post(process.env.VUE_APP_API_URL + 'fornecedor', data, { headers: authHeader() })
    }
  }

  //cancelamento
  getCancelamentos ( data ) {
    const nomePessoa = data.nomePessoa ? data.nomePessoa : 0
    const unidade = data.unidade? data.unidade : 0
    const periodoLetivo = data.periodoLetivo ? data.periodoLetivo : 0
    const idCancelamento = data.idCancelamento ? data.idCancelamento : 0
    const dataInicio = data.dataInicio ? data.dataInicio : 0
    const dataFim = data.dataFim ? data.dataFim : 0
    const statusPendente = data.pendente ? data.pendente : 0
    const statusAprovado = data.aprovado ? data.aprovado : 0
    const statusReprovado = data.reprovado ? data.reprovado : 0
    const statusAguardandoEntrega = data.aguardandoEntrega ? data.aguardandoEntrega : 0
    const statusFinalizado = data.finalizado ? data.finalizado : 0

    return axios.get( process.env.VUE_APP_API_URL + `loja/get/cancelamento/${nomePessoa}/${unidade}/${periodoLetivo}/${idCancelamento}/${dataInicio}/${dataFim}/${statusPendente}/${statusAprovado}/${statusReprovado}/${statusAguardandoEntrega}/${statusFinalizado}`, { headers: authHeader() })
  }

  getSolicitacoesAtendimento () {
    return axios.get(process.env.VUE_APP_API_URL + 'loja/get/cancelamento/atendimento', { headers: authHeader() })
  }

  getSolicitacoesCR () {
    return axios.get(process.env.VUE_APP_API_URL + 'loja/get/cancelamento/financeiro', { headers: authHeader() })
  }

  getCancelamento ( idSolicitacaoCancelamento ) {
    return axios.get(process.env.VUE_APP_API_URL + 'loja/get/cancelamento/' + idSolicitacaoCancelamento, { headers: authHeader() })
  }

  getMotivosRecusaCancelamento () {
    return axios.get(process.env.VUE_APP_API_URL + 'loja/getmotivorecusa/cancelamento/mp', { headers: authHeader() })
  }

  getTipoVoucher () {
    return axios.get(process.env.VUE_APP_API_URL + 'loja/get/tiposvoucher', { headers: authHeader() })
  }

  engageAtendimento ( idSolicitacaoCancelamento, data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'loja/validacao/inicio/cancelamento/' + idSolicitacaoCancelamento, data, { headers: authHeader() })
  }

  engageCR ( idSolicitacaoCancelamento, data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'loja/validacao/inicio/cr/cancelamento/' + idSolicitacaoCancelamento, data, { headers: authHeader() })
  }

  approveSolicitacaoCancelamento ( idSolicitacaoCancelamento, data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'loja/validacao/cancelamento/' + idSolicitacaoCancelamento, data, { headers: authHeader() })
  }

  finalizarSolicitacaoCancelamento ( idSolicitacaoCancelamento, data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'loja/execucao/cancelamento/' + idSolicitacaoCancelamento, data, { headers: authHeader() })
  }
  
  receberItensCancelamento ( idSolicitacaoCancelamento, data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'loja/validacao/cancelamento/devolucao/' + idSolicitacaoCancelamento, data, { headers: authHeader() })
  }
  getMotivosCancelamento () {
    return axios.get(process.env.VUE_APP_API_URL + 'loja/getmotivocancelamento/mp', { headers: authHeader() })
  }

  saveSolcitacaoCancelamento ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'loja/solicitacao/cancelamento', data, { headers: authHeader() })
  }

  getVouchersResponsavel ( idPessoa ) {
    return axios.get(process.env.VUE_APP_API_URL + 'loja/get/vouchers/meusvoucher/' + idPessoa, { headers: authHeader() })
  }
}

export default new MarketplaceService()