<template>
  <footer>
    <div class="frame">
      <div>
        <h4>Deixe nos ajudá-lo</h4>
        <nav>
          <a class="btn btn-primary icon-on-hover" href="https://www.youtube.com/watch?v=Cq1ehEt8B-8" target="_blank">
            <span>Tutorial de Compra</span>
            <fa :icon="['far', 'up-right-from-square']" class="icon" />
          </a>
          <a class="btn btn-primary icon-on-hover" href="https://rededecisao.com.br/me-ajuda/" target="_blank">
            <span>Me Ajuda</span>
            <fa :icon="['far', 'up-right-from-square']" class="icon" />
          </a>
          <a class="btn btn-primary icon-on-hover" href="https://rededecisao.com.br/me-ajuda?cms-nome=Medidas#shopdecisao" target="_blank">
            <span>Guia de Tamanho dos Uniformes</span>
            <fa :icon="['far', 'up-right-from-square']" class="icon" />
          </a>
          <!-- <a class="btn btn-primary icon-on-hover" :href="returnListLink()" target="_blank">
            <span>Lista de Materiais 2022</span>
            <fa :icon="['far', 'up-right-from-square']" class="icon" />
          </a> -->
        </nav>
      </div>
      <!-- <div>
        <h4>Suporte e Feedbacks</h4>
        <nav>
          <a class="btn btn-primary icon-on-hover" href="https://rededecisao.sasconecta.com.br/Eteg.Cronus.Web_deploy/Acesso/Login?ReturnUrl=%2f#!/home" target="_blank">
            <span>Canais de Atendimento</span>
            <fa :icon="['far', 'up-right-from-square']" class="icon" />
          </a>
          <a class="btn btn-primary icon-on-hover" href="https://sites.google.com/rededecisao.com.br/plataformaremota/blog-shop-decis%C3%A3o/perguntas-e-respostas" target="_blank">
            <span>Perguntas e Respostas</span>
            <fa :icon="['far', 'up-right-from-square']" class="icon" />
          </a>
        </nav>
      </div> -->
      <div>
        <h4>Políticas e contratos</h4>
        <nav>
          <a class="btn btn-primary icon-on-hover" href="https://rededecisao.com.br/me-ajuda/#politicacancelamento" target="_blank">
            <span>Política de devolução e cancelamento</span>
            <fa :icon="['far', 'up-right-from-square']" class="icon" />
          </a>
          <a class="btn btn-primary icon-on-hover" href="https://api.rededecisao.com/marketplace/documentos/rede_decisao-termo_de_adesao_shop_decisao.pdf" target="_blank">
            <span>Termos de adesão ou compra</span>
            <fa :icon="['far', 'up-right-from-square']" class="icon" />
          </a>
        </nav>
      </div>
      <div></div>
      <div class="logoWrap">
        <logo color="#FFF" :size="logoSize()"></logo>
      </div>
    </div>
  </footer>
</template>

<script>
import Logo from '@/components/template/logo.vue'

export default {
  name: 'Footer',
  components: {
    Logo
  },
  data () {
    return {

    }
  },
  mounted () {

  },
  methods: {
    logoSize () {
      return window.innerWidth < 1024 ? '40px' : '50px'
    },
    returnListLink () {
      return process.env.VUE_APP_PRODUCT_URL + 'LISTA_DE_MATERIAL-2022.pdf'
    }
  }
}
</script>

<style lang="scss" scoped>
  footer {
    width: 100%; height: auto;
    background-color: $color-primary;
    padding: $mg $hmg;
    margin-top: $mg;

    > div {
      display: flex; align-content: center; align-items: flex-start; justify-content: space-between;

      h4 {
        display: block;
        color: $color-secondary;
        padding-left: $hmg_small;
        margin-bottom: $hmg_small;
      }

      nav {
        display: flex; flex-direction: column;
        align-items: flex-start; align-content: flex-start;

        .btn {
          text-align: left; min-width: none; width: auto !important;
          padding-left: $hmg_small; padding-right: $hmg_small;
        }
      }

      @media screen and ( max-width: 1024px ) {
        flex-direction: column;

        > div {
          margin-bottom: $hmg;
        }

        .logoWrap {
          width: 100%;
          display: flex; justify-content: center;
          margin-top: $mg;
        }
      }
    }
  }
</style>