<script setup>
  import FinanceiroService from '@/services/financeiro.service.js';
</script>
<template>
  <section class="extrato">
    <header class="frame">
      <h1>Extrato Financeiro</h1>
    </header>
    <section class="frame">
      <header>
        
      </header>
      <div>
        
      </div>
    </section>
  </section>
</template>

<script>
export default {
  props: {
    idPessoa: {
      type: [Number, String]
    }
  },
  data () {
    return {
      range: {
        inicio: '2024-10-01',
        fim: '2025-01-01',
      }
    }
  },
  mounted () {
    if ( this.idPessoa ) {
      this.getFicha()
    }
  },
  methods: {
    getFicha () {
      const options = {
        idPessoa: this.idPessoa,
        ...this.range
      }

      FinanceiroService.getFichaFinanceira(options).then(
        response => {
          console.log('getFichaFinanceira:', response.data)
        },
        error => {
          console.error(error)
        }
      )
      console.log("options:", options)
    }
  }
}
</script>

<style lang="scss" scoped>
  .extrato {
    padding: $mg;

    > header {
      padding: $hmg_mid 0  
    } 
  }
</style>